/* eslint-disable no-console */
import React, { FC, useEffect, useState } from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import IconButton from "@mui/material/IconButton";

import { toggleArtworkLike } from "../../api-queries/GraohQlClient";
import { getLikedArtworksIds } from "./utils";
import { useActions } from "../../hooks/useActions";

import "./LikeArtworkButton.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";

type SetLikesCount = (param: number) => number;

interface LikeArtworkButtonProps {
  likesCount: number;
  setLikesCount: (param: number | SetLikesCount) => void;
  refetch: () => void;
  artworkId: string | undefined;
}

const LikeArtworkButton: FC<LikeArtworkButtonProps> = ({
  likesCount,
  setLikesCount,
  refetch,
  artworkId,
}) => {
  const token = localStorage.getItem("tokenKey");
  const [isArtworkLiked, setIsArtworkLiked] = useState(false);
  const { userLikedArtworks } = useTypedSelector(store => store.userLikedArtworksReducer);
  const { setUserLikedArtworks } = useActions();

  const { openModalLogin } = useActions();

  const onLikeButtonClick = async (): Promise<void> => {
    try {
      const isLiked = await toggleArtworkLike(artworkId);
      setIsArtworkLiked(isLiked.liked);

      if (isLiked.liked) {
        setLikesCount(prev => prev + 1);
      } else {
        setUserLikedArtworks(userLikedArtworks.filter(artwork => artwork._id !== artworkId));
        setLikesCount(prev => prev - 1);
      }
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  const setLike = async (): Promise<void> => {
    try {
      const likedArtworksIds = await getLikedArtworksIds();
      if (likedArtworksIds.some(item => item === artworkId)) setIsArtworkLiked(true);
    } catch (err) {
      console.error(err);
    }
  };

  const onLikeClick = token ? onLikeButtonClick : openModalLogin;

  useEffect(() => {
    if (!token) return;

    setLike();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likesCount]);

  return (
    <div className="like_artwork_button">
      <IconButton onClick={onLikeClick} style={{ padding: 3 }}>
        {isArtworkLiked ? (
          <FavoriteIcon style={{ color: "red" }} fontSize="small" />
        ) : (
          <FavoriteBorderIcon fontSize="small" />
        )}
      </IconButton>
      <span className="like_artwork_button__liked">{likesCount} Liked</span>
    </div>
  );
};

export default LikeArtworkButton;
