import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { Avatar, Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { ControllerItem, LoginButtonMenuProps } from "./LoginButtonMenu.props";
import {
  about,
  artists,
  blockedUsers,
  editInstitutionProfile,
  editOriginsAndInspiration,
  editProfile,
  explore,
} from "../../route-link";
import { useActions } from "../../hooks/useActions";
import { paperProps } from "./LoginButtonMenu.style";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { UserRequests } from "../../api-queries/AllRequests";
import AvatarPlaceholder from "../../components/avatar-placeholder/AvatarPlaceholder";
import {
  artistsControllers,
  artShowsControllers,
  getPendingActionsControllers,
} from "./controllers.data";
import "./LoginButtonMenu.scss";
import DialogWindow from "../../components/dialog-window/DialogWindow";
import DialogContent from "../dialog-content/DialogContent";
import { removeUser } from "../../api-queries/GraohQlClient";

const LoginButtonMenu: React.FC<LoginButtonMenuProps> = () => {
  const { headerFlag } = useActions();
  const location = useLocation();
  const getLocalStorageItem = localStorage.getItem("tokenKey") || "";
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const { loginFlag } = useTypedSelector(state => state.loginReducer);
  const navigate = useNavigate();
  const [tokenKey, setTokenKey] = useState(getLocalStorageItem);
  const [logoutBoolean, setLogoutBoolean] = useState(false);
  const [prevUserName, setPrevUserName] = useState<string | null>(null);
  const [userAvatarUrl, setUserAvatarUrl] = useState("");
  const { openModal } = useActions();
  const [showDeleteAccountPopup, setShowDeleteAccountPopup] = useState(false);

  const { data: userInfo, refetch } = useQuery(UserRequests.USER_CONTROLS, {
    variables: { _id: authUserId },
  });
  const userProfile = userInfo?.user?.profile;
  const isInstitution = Boolean(
    userInfo?.user?.institutions.length && userInfo?.user?.hasInstitution,
  );
  const userName =
    userInfo && isInstitution
      ? userInfo?.user?.institutions[0].name
      : userProfile &&
        `${userProfile?.firstName} ${userProfile?.middleName} ${userProfile?.secondName}`;
  const goToMyProfile = (): void => {
    if (isInstitution) {
      openModal();
      // navigate(`${explore}/${artists}/${userInfo?.user?.institutions[0]?.owner?._id}`);
      return;
    }
    navigate(`${explore}/${artists}/${authUserId}`, {
      state: { image: userAvatarUrl, name: prevUserName || userName },
    });
  };
  useEffect(() => {
    setTokenKey(getLocalStorageItem);
  }, [logoutBoolean, loginFlag, tokenKey]);

  useEffect(() => {
    const prevUserInfoJSON = localStorage.getItem("userInfo");
    if (!prevUserInfoJSON) return;
    const parsedUserInfo: { image: string; name: string } = JSON.parse(prevUserInfoJSON);
    setUserAvatarUrl(parsedUserInfo.image);
    setPrevUserName(parsedUserInfo.name);
  }, []);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  const profileControllers = (): Array<ControllerItem> => {
    const allControllers = artistsControllers(authUserId);
    if (isInstitution) {
      return allControllers.filter(controller => controller.title !== "My Created Art");
    }
    return allControllers;
  };

  const artShowsUserControllers = (): Array<ControllerItem> => {
    return artShowsControllers(authUserId);
  };

  const pendingActionsControllers = getPendingActionsControllers(
    authUserId,
    userInfo?.user?.collectionRequests?.length,
  );

  useEffect(() => {
    if (userInfo?.user?.profile) {
      setUserAvatarUrl(userInfo?.user?.profile?.image?.thumbnail?.url);
      setPrevUserName(null);
      localStorage.setItem(
        "userInfo",
        JSON.stringify({ name: userName, image: userInfo.user.profile.image?.thumbnail?.url }),
      );
    }
  }, [userInfo]);

  const { openModalLogin, setAuthorizedUser } = useActions();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const logout = (): void => {
    headerFlag();
    setUserAvatarUrl("");
    setTokenKey("");
    setPrevUserName("");
    setLogoutBoolean(prevState => !prevState);
    localStorage.clear();
    setAuthorizedUser(null);
    navigate(explore);
  };

  const goToEditPublicProfile = (): void => {
    return isInstitution
      ? navigate(
          `${explore}/${artists}/${userInfo?.user?.institutions[0]?.owner?._id}/${editProfile}`,
          {
            state: { userInfo, isInstitution },
          },
        )
      : navigate(`/${explore}/${artists}/${authUserId}/${editProfile}`, {
          state: { userInfo },
        });
  };

  const goToEdit = (): void => {
    return isInstitution
      ? navigate(
          `/${explore}/${artists}/${userInfo?.user?.institutions[0]?.owner?._id}/${editInstitutionProfile}`,
          {
            state: { data: userInfo.user },
          },
        )
      : navigate(`/${explore}/${artists}/${authUserId}/${editOriginsAndInspiration}`, {
          state: { data: userInfo.user },
        });
  };

  const goToBlockedUsersPage = (): void => {
    navigate(`/${explore}/${blockedUsers}`);
  };

  const editTitle = "Edit my Public Profile";

  const editOriginsOfInspirationsTitle = isInstitution
    ? "Edit institution info"
    : "Edit my origins of inspiration search terms";

  const openDeleteAccountPopup = (): void => {
    setShowDeleteAccountPopup(true);
  };

  const closeDeleteAccountPopup = (): void => {
    setShowDeleteAccountPopup(false);
  };

  const handleDeleteProfile = async (): Promise<void> => {
    try {
      logout();

      await removeUser({
        userId: authUserId,
      });
    } catch (error) {
      console.error(error);
    }

    closeDeleteAccountPopup();
  };

  return (
    <>
      {!tokenKey && (
        <Button onClick={openModalLogin} className="login_button" variant="contained">
          Sign in
        </Button>
      )}
      {tokenKey && (
        <>
          <DialogWindow
            open={showDeleteAccountPopup}
            handleClose={closeDeleteAccountPopup}
            title="Are you sure you want to delete your account?"
            className="remove"
          >
            <DialogContent
              handleDialogClose={closeDeleteAccountPopup}
              handleRemove={handleDeleteProfile}
            />
          </DialogWindow>
          <Tooltip title="Account settings">
            <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
              {userAvatarUrl ? (
                <Avatar src={userAvatarUrl} sx={{ width: 32, height: 32 }} />
              ) : (
                <AvatarPlaceholder id={authUserId} width="32px" height="32px" fontSize="8px" />
              )}
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            PaperProps={{
              sx: paperProps,
            }}
          >
            <div className="account">
              <div className="your_account">Controls</div>
              <div className="account_avatar">
                {userAvatarUrl ? (
                  <Avatar className="my_avatar" src={userAvatarUrl} onClick={goToMyProfile} />
                ) : (
                  <AvatarPlaceholder
                    onClick={goToMyProfile}
                    className="MuiAvatar-root"
                    id={authUserId}
                    width="50px"
                    height="50px"
                    fontSize="8px"
                  />
                )}
                <div className="account_name__wrap">
                  <div onClick={goToMyProfile} className="account_name">
                    {prevUserName || userName}
                  </div>
                  <div className="account_avatar__edit" onClick={goToEditPublicProfile}>
                    {editTitle}
                  </div>
                  <div className="account_avatar__edit" onClick={goToEdit}>
                    {editOriginsOfInspirationsTitle}
                  </div>
                  <div className="account_avatar__edit" onClick={goToBlockedUsersPage}>
                    Users blocked by me
                  </div>
                </div>
              </div>

              <Divider />
              <div className="controller_list__title">Pending Action</div>
              {pendingActionsControllers.map((controller: ControllerItem) => (
                <MenuItem key={controller.id} className="controller_list__item">
                  <Link
                    onClick={e => {
                      if (controller.block) {
                        e.preventDefault();
                        openModal();
                      }
                    }}
                    to={controller.link}
                  >
                    {controller.title}
                  </Link>
                </MenuItem>
              ))}
              <Divider />
              <div className="controller_list__title">
                The New York Museum of Contemporary Art Shows
              </div>
              {artShowsUserControllers().map((controller: ControllerItem) => (
                <MenuItem key={controller.id} className="controller_list__item">
                  <Link
                    onClick={e => {
                      if (controller.block) {
                        e.preventDefault();
                        openModal();
                      }
                    }}
                    to={controller.link}
                  >
                    {controller.title}
                  </Link>
                </MenuItem>
              ))}
              <Divider />
              <div className="controller_list__title">Art history</div>
              {profileControllers().map((controller: ControllerItem) => (
                <MenuItem key={controller.id} className="controller_list__item">
                  <Link
                    onClick={e => {
                      if (controller.block) {
                        e.preventDefault();
                        openModal();
                      }
                    }}
                    to={controller.link}
                  >
                    {controller.title}
                  </Link>
                </MenuItem>
              ))}
              <Divider />
              <MenuItem className="controller_list__item">
                <Link to={about}>About</Link>
              </MenuItem>
              <div className="privacy_terms">
                <Link to="/privacy-policy" className="controller_nav__item">
                  Privacy
                </Link>{" "}
                and{" "}
                <Link className="controller_nav__item" to="/terms-of-use">
                  {" "}
                  Terms of Use
                </Link>
              </div>
              <MenuItem onClick={openDeleteAccountPopup} className="controller_list__item">
                Delete Account
              </MenuItem>
              <Divider />
              <MenuItem className="controller_logout" onClick={logout}>
                Log Out
              </MenuItem>
            </div>
          </Menu>
        </>
      )}
    </>
  );
};

export default LoginButtonMenu;
