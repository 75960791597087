import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { artists, explore } from "../../route-link";
import NotificationTime from "./NotificationTime";
import { UserRequests } from "../../api-queries/AllRequests";

interface InviteToShowNotificationProps {
  nameFrom: string;
  userId: string;
  titleOfPost: string;
  time: Date;
}

const InviteToShowNotification: React.FC<InviteToShowNotificationProps> = ({
  userId,
  nameFrom,
  titleOfPost,
  time,
}): JSX.Element => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const { data: userData } = useQuery(UserRequests.USER_DETAILS, {
    variables: { _id: authUserId },
  });
  const nameTo = `${userData?.user.profile.firstName} ${userData?.user.profile.secondName}`;
  return (
    <div className="notification_grid">
      <p className="notification_text">
        <Link className="notification_link" to={`${explore}/${artists}/${userId}`}>
          {nameFrom}
        </Link>{" "}
        invited{" "}
        <Link className="notification_link" to={`${explore}/${artists}/${authUserId}`}>
          {nameTo}
        </Link>{" "}
        to exhibit in{" "}
        <Link className="notification_link" to="/">
          {titleOfPost}
        </Link>{" "}
      </p>
      <NotificationTime time={time} />
    </div>
  );
};

export default InviteToShowNotification;
