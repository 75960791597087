import React, { FC, useState } from "react";
import ReplyIcon from "@mui/icons-material/Reply";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import "./ShareButton.scss";
import { useActions } from "../../hooks/useActions";

type SetSharesCount = (param: number) => number;

interface ShareButtonProps {
  setSharesCount?: (param: number | SetSharesCount) => void;
  submitShare?: (param: string) => Promise<void>;
  refetch?: () => void;
  id?: string;
}

const ShareButton: FC<ShareButtonProps> = ({ submitShare, setSharesCount, refetch, id }) => {
  const [title, setTitle] = useState("Copy link");
  const { openModalLogin } = useActions();
  const token = localStorage.getItem("tokenKey");

  const getLocationHref = async (): Promise<void> => {
    navigator.clipboard.writeText(window.location.href);
    setTitle("Copied");

    if (!token && submitShare) {
      openModalLogin();
      return;
    }

    if (submitShare && id && setSharesCount && refetch) {
      await submitShare(id);
      setSharesCount(prev => prev + 1);
      refetch();
    }
  };

  return (
    <Tooltip onOpen={() => setTitle("Copy link")} title={title}>
      <IconButton
        color="inherit"
        style={{ padding: 2 }}
        onClick={getLocationHref}
        className="share-button"
      >
        <ReplyIcon className="share-button__icon" />
      </IconButton>
    </Tooltip>
  );
};

export default ShareButton;
