import React, { memo, useRef, useState } from "react";

import ExploreItemDetailsRight from "../explore-item-details-right/ExploreItemDetailsRight";
import ImageLightbox from "../image-lightbox/ImageLightbox";
import ScrollToTop from "../scroll-to-top/ScrollToTop";
import { artworkDeatils } from "../../api-queries/GraohQlClient";

import "./ArtWorksExploreItemDetails.scss";
import { CreateTransferContextProvider } from "../../context/createTransferContext";

const ArtWorksExploreItemDetails = ({
  id,
  artwork,
  removeArtworkFromSlider,
  blockTransferModal,
  setBlockSliding,
}: any): JSX.Element => {
  const [artworkItem, setArtworkItem] = useState(artwork);
  const suggestions = useRef(artwork?.suggestions?.filter((item: any) => !item.iDontKnow));
  const artworkOwnerId = artwork?.owner?._id;
  const authUserId = localStorage.getItem("myMOCAId") || "";

  const showOwnerActionsButtons = Boolean(
    (artworkOwnerId === authUserId && artwork?.type === "artist" && artwork?.collector === null) ||
      (artworkOwnerId === authUserId &&
        artwork?.type === "collector" &&
        artwork?.collector !== null &&
        artwork?.collector._id === authUserId) ||
      (artworkOwnerId === authUserId && artwork?.type === "found" && artwork?.collector === null),
  );
  const artworkCollectorId = artwork?.collector?._id;
  const showCollectorActionButtons = Boolean(
    artwork?.collector !== null && artworkCollectorId === authUserId,
  );

  const refetchArtwork = async (): Promise<void> => {
    const response = await artworkDeatils({ artworkId: id });
    suggestions.current = response.artwork.suggestions.filter((item: any) => !item.iDontKnow);
    setArtworkItem(response.artwork);
  };

  return (
    <div className="artwork_details_page">
      <ScrollToTop />
      <div className="artworks_explore_item_details">
        <div className="light_box__wrap">
          <ImageLightbox
            mainImage={artwork?.image}
            images={artwork?.additionalImages || []}
            artworkId={id}
            showOwnerActionsButtons={showOwnerActionsButtons}
            showCollectorActionButtons={showCollectorActionButtons}
          />
        </div>
        <CreateTransferContextProvider filmsAndTv={artworkItem.allowUseForFilmAndTv}>
          <ExploreItemDetailsRight
            artworkId={id}
            artwork={artworkItem}
            refetch={refetchArtwork}
            suggestions={suggestions.current}
            showOwnerActionsButtons={showOwnerActionsButtons}
            showCollectorActionButtons={showCollectorActionButtons}
            removeArtworkFromSlider={removeArtworkFromSlider}
            blockTransferModal={blockTransferModal}
            setBlockSliding={setBlockSliding}
          />
        </CreateTransferContextProvider>
      </div>
    </div>
  );
};
export default memo(ArtWorksExploreItemDetails);
