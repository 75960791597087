import React, { useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-masonry-css";
import { Link, useOutletContext, useLocation } from "react-router-dom";

import { ArtworksRequests } from "../../api-queries/AllRequests";
import LeafletMap from "../leaflet-maps/LeafletMap";
import { useActions } from "../../hooks/useActions";
import { BREAKPOINT_COLUMNS_OBJ } from "../../misc/constants";

import { getUniqueArtworks } from "./utils";
import ExplorePageContext from "../../context/exploreContext";
import "./ArtworksExploreList.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useArtworksSldrCntx } from "../../context/artworksSldrCntx";

const ArtworksExploreList = () => {
  const [loadArtworks, { loading, data, refetch }] = useLazyQuery(
    ArtworksRequests.RANDOM_ARTWORKS_IMAGE,
    {
      variables: { limit: 50 },
    },
  );
  const { setArtworks: setSldrAtrwrs } = useArtworksSldrCntx();

  const { states } = useTypedSelector(state => state.prevStateReducer);

  const location = useLocation();

  const { openModal, addPrevState, removePrevState } = useActions();

  const { explorePageArtworks: artworks, setExplorePageArtworks: setArtworks } =
    useOutletContext(ExplorePageContext).exploreListContext;

  const masonryColumns = useRef();
  const widthBlock = useRef();

  const scrollHandler = () => {
    refetch();
    setArtworks(prevArtworks => [...prevArtworks, ...(data?.randomArtworks || [])]);
  };

  useEffect(() => {
    (async () => {
      const prevState = states.find(prevSt => prevSt.url === location.pathname);
      const prevLoadingStateJSON = localStorage.getItem("prevState");
      const prevLoadingState = prevLoadingStateJSON ? JSON.parse(prevLoadingStateJSON) : null;

      if (prevState) {
        refetch();
        setArtworks([...prevState.state, ...(data?.randomArtworks || [])]);
        removePrevState("/explore");
        return;
      }

      if (prevLoadingState?.url === location.pathname) {
        localStorage.removeItem("prevState");
        refetch();
        setArtworks([...prevLoadingState.state, ...(data?.randomArtworks || [])]);
      }
      loadArtworks();
      setArtworks(prev => [...prev, ...(data?.randomArtworks || [])]);
    })();
  }, [loading]);

  const uniqArtworks = getUniqueArtworks(artworks);

  const setPrevState = () => {
    addPrevState({ url: location.pathname, state: uniqArtworks });
    localStorage.setItem(
      "prevState",
      JSON.stringify({ url: location.pathname, state: uniqArtworks }),
    );
    setSldrAtrwrs(uniqArtworks.map(el => ({ id: el._id, artwork: null })));
  };
  const widthColumn =
    widthBlock?.current?.offsetWidth / masonryColumns.current?.state?.columnCount - 12;

  const ArtworkItem = ({ artwork }) => {
    const paramsArtwork = artwork.image.medium.width / artwork.image.medium.height;
    return (
      <div
        className="artwork_item"
        onClick={setPrevState}
        style={{ minHeight: Math.floor(widthColumn / paramsArtwork) }}
      >
        <Link to={{ pathname: `/explore/artworks/${artwork._id}` }} state={artwork}>
          <img className="artwork_item__image" src={artwork.image.medium.url} alt={artwork.name} />
        </Link>
      </div>
    );
  };

  return (
    <div className="artworks_explore wrapper">
      <LeafletMap
        setIsOpen={openModal}
        saveState={setPrevState}
        locations={uniqArtworks}
        zoom={3}
      />
      <div className="explore_list">
        {uniqArtworks && (
          <InfiniteScroll
            scrollThreshold={0.7}
            dataLength={artworks.length}
            next={scrollHandler}
            hasMore
          >
            <div ref={widthBlock}>
              <Masonry
                ref={masonryColumns}
                breakpointCols={BREAKPOINT_COLUMNS_OBJ}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {uniqArtworks.map(artwork => (
                  <ArtworkItem key={artwork._id} artwork={artwork} />
                ))}
              </Masonry>
            </div>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};
export default ArtworksExploreList;
