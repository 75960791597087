import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";

import { ActionsNotice } from "./Notifications.props";
import AvatarPlaceholder from "../avatar-placeholder/AvatarPlaceholder";
import { NotificationItemEnum } from "./NotificationItem.enum";
import { artists, explore } from "../../route-link";
import Spider from "../../assets/images/spider.jpeg";

import FollowNotification from "./FollowNotification";
import PostNotification from "./PostNotification";
import LikeArtworkNotification from "./LikeArtworkNotification";
import LikeNotification from "./LikeNotification";
import PublicCommentsArtworkNotification from "./PublicCommentsArtworkNotification";
import PublicCommentsPostNotification from "./PublicCommentsPostNotification";
import PublicCommentsShowNotification from "./PublicCommentsShowNotification";
import ReplyCommentsNotification from "./ReplyCommentsNotification";
import TransferNotification from "./TransferNotification";
import InviteToShowNotification from "./InviteToShowNotification";
import VoteArtworkNotification from "./VoteArtworkNotification";
import AcceptArtworkSubmissionNotification from "./AcceptArtworkSubmissionNotification";
import DeclineTransferArtworkNotification from "./DeclineTransferArtworkNotification";
import ScanArtworkNotification from "./ScanArtworkNotification";
import SubmitArtworkNotification from "./SubmitArtworkNotification";
import SharedArtworkNotification from "./SharedArtworkNotification";
import SharedExhibitionNotification from "./SharedExhibitionNotification";

const NotificationItem = ({ notice }: ActionsNotice): JSX.Element => {
  const nameFollower = `${notice?.user.profile.firstName} ${notice?.user.profile.secondName}` || "";
  const userId = notice?.user._id;
  const [userAvatarUrl, setUserAvatarUrl] = useState("");
  const [bodyNotification, setBodyNotification] = useState<any>(null);

  const textComment = notice?.associated.comment?.text || "";
  const image = notice?.audited?.artwork?.image?.thumbnail.url || Spider;
  const imageOfPost = notice?.audited?.post?.imagesOfPost[0]?.thumbnail.url || "";
  const titleOfPost = notice?.audited?.exhibition?.title || "";
  const nameArtwork = notice?.audited?.artwork?.name || "nameArtwork";
  const artworkId = notice?.audited?.artwork?._id || "";
  useEffect(() => {
    if (notice?.user.profile.image?.thumbnail.url) {
      setUserAvatarUrl(notice?.user.profile.image?.thumbnail.url);
      return;
    }
    setUserAvatarUrl("");
  }, []);

  useEffect(() => {
    switch (notice.type) {
      case NotificationItemEnum.followUser:
        setBodyNotification(
          <FollowNotification
            nameFollower={nameFollower}
            time={new Date(notice.createdAt)}
            userId={userId}
          />,
        );
        break;
      case NotificationItemEnum.likePost:
        setBodyNotification(
          <PostNotification
            type="liked "
            nameFollower={nameFollower}
            time={new Date(notice.createdAt)}
          />,
        );
        break;
      case NotificationItemEnum.sharePost:
        setBodyNotification(
          <PostNotification
            type="shared "
            nameFollower={nameFollower}
            time={new Date(notice.createdAt)}
          />,
        );
        break;
      case NotificationItemEnum.likeArtwork:
        setBodyNotification(
          <LikeArtworkNotification
            imageSrc={image}
            nameAvatar="Avatar"
            userId={userId}
            nameFollower={nameFollower}
            time={new Date(notice.createdAt)}
            artworkId={artworkId}
          />,
        );
        break;
      case NotificationItemEnum.likeComment:
        setBodyNotification(
          <LikeNotification
            nameFollower={nameFollower}
            time={new Date(notice.createdAt)}
            textComment={textComment}
          />,
        );
        break;
      case NotificationItemEnum.commentArtwork:
        setBodyNotification(
          <PublicCommentsArtworkNotification
            artworkId={artworkId}
            time={new Date(notice.createdAt)}
            nameFollower={nameFollower}
            userId={userId}
            imageSrc={image}
            textComment={textComment}
          />,
        );
        break;
      case NotificationItemEnum.commentPost:
        setBodyNotification(
          <PublicCommentsPostNotification
            time={new Date(notice.createdAt)}
            nameFollower={nameFollower}
            userId={userId}
            imageSrc={imageOfPost}
            textComment={textComment}
          />,
        );
        break;
      case NotificationItemEnum.commentExhibition:
        setBodyNotification(
          <PublicCommentsShowNotification
            notice={notice}
            time={new Date(notice.createdAt)}
            nameFollower={nameFollower}
            userId={userId}
            textComment={textComment}
          />,
        );
        break;
      case NotificationItemEnum.commentReply:
        setBodyNotification(
          <ReplyCommentsNotification
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            userId={userId}
            textComment={textComment}
          />,
        );
        break;
      case NotificationItemEnum.transferArtworkRequest:
        setBodyNotification(
          <TransferNotification
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            userId={userId}
            imageSrc={Spider}
          />,
        );
        break;
      case NotificationItemEnum.inviteToExhibition:
        setBodyNotification(
          <InviteToShowNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            titleOfPost={titleOfPost}
          />,
        );
        break;
      case NotificationItemEnum.voteArtwork:
        setBodyNotification(
          <VoteArtworkNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
          />,
        );
        break;
      case NotificationItemEnum.acceptArtworkSubmission:
        setBodyNotification(
          <AcceptArtworkSubmissionNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            imageSrc={image}
            nameAcceptArtwork={nameArtwork}
            artworkId={artworkId}
          />,
        );
        break;
      case NotificationItemEnum.declineTransferArtworkRequest:
        setBodyNotification(
          <DeclineTransferArtworkNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            imageSrc={image}
            artworkId={artworkId}
            nameArtwork={nameArtwork}
          />,
        );
        break;
      case NotificationItemEnum.scanArtwork:
        setBodyNotification(
          <ScanArtworkNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            imageSrc={image}
            artworkId={artworkId}
            nameArtwork={nameArtwork}
          />,
        );
        break;
      case NotificationItemEnum.submitArtworkToExhibition:
        setBodyNotification(
          <SubmitArtworkNotification
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            imageSrc={image}
            artworkId={artworkId}
          />,
        );
        break;
      case NotificationItemEnum.shareArtwork:
        setBodyNotification(
          <SharedArtworkNotification
            nameArtwork={nameArtwork}
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            imageSrc={image}
            artworkId={artworkId}
          />,
        );
        break;
      case NotificationItemEnum.shareExhibition:
        setBodyNotification(
          <SharedExhibitionNotification
            nameArtwork={nameArtwork}
            userId={userId}
            time={new Date(notice.createdAt)}
            nameFrom={nameFollower}
            imageSrc={image}
            artworkId={artworkId}
          />,
        );
        break;
      default:
        setBodyNotification(null);
    }
  }, []);
  return (
    <div className="notification_item">
      <Link to={`${explore}/${artists}/${userId}`} className="notification_avatar">
        {userAvatarUrl ? (
          <Avatar src={userAvatarUrl} sx={{ width: 32, height: 32 }} />
        ) : (
          <AvatarPlaceholder id={userId} width="32px" height="32px" fontSize="8px" />
        )}
      </Link>
      <div className="notification_body">{bodyNotification}</div>
    </div>
  );
};

export default NotificationItem;
