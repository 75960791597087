import React from "react";
import { Link } from "react-router-dom";
import { artists, explore } from "../../route-link";
import NotificationTime from "./NotificationTime";

interface VoteArtworkNotificationProps {
  nameFrom: string;
  userId: string;
  time: Date;
}

const VoteArtworkNotification: React.FC<VoteArtworkNotificationProps> = ({
  nameFrom,
  userId,
  time,
}): JSX.Element => {
  return (
    <div className="notification_grid">
      <div className="notification_text">
        <Link className="notification_link" to={`${explore}/${artists}/${userId}`}>
          {nameFrom}
        </Link>{" "}
        voted for artwork
      </div>
      <NotificationTime time={time} />
    </div>
  );
};

export default VoteArtworkNotification;
