import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Parallax } from "react-parallax";
import Masonry from "react-masonry-css";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import ExplorePageTitle from "../../components/explore-page-title/ExplorePageTitle";
import { artworksForVote } from "../../api-queries/GraohQlClient";
import { BREAKPOINT_COLUMNS_OBJ } from "../../misc/constants";
import WinnersList from "./components/winners-list/WinnersList";

import { ArtworksRequests } from "../../api-queries/AllRequests";
import VoteListArtworks from "./components/vote-list-artworks/VoteListArtworks";
import TooltipTextVote from "../../components/explore-page-title/components/TooltipTextVote";
import bgImage from "../../assets/images/exploreIllustration6.jpeg";
import "./VoteExplorePage.scss";
import { IArtwork } from "../../types/artwork/artwork";

const VoteExplorePage = (): JSX.Element => {
  const { data } = useQuery(ArtworksRequests.SEARCH_ARTWORKS_LITE, {
    variables: { isWinner: true, limit: 10 },
  });
  const [offset, setOffset] = useState(0);
  const limit = 50;
  const [dataVoteArtworks, setDataVoteArtworks] = useState<IArtwork[]>([]);
  const masonryColumns: any = useRef();
  const widthBlock: any = useRef();

  const getArtworksForVote = async (): Promise<void> => {
    const dataArt = await artworksForVote({
      limit,
      offset,
    });
    setOffset(prev => prev + limit);
    setDataVoteArtworks(prev => [...prev, ...dataArt?.artworks]);
  };

  const scrollHandler = (): void => {
    getArtworksForVote();
  };

  useEffect(() => {
    getArtworksForVote();
  }, []);

  return (
    <div className="vote">
      <div className="wrapper">
        <div className="page_background">
          <Parallax bgClassName="page_background__image" bgImage={bgImage} strength={500}>
            <div className="page_background__title">
              Curate The New York <br /> Museum of <br /> Contemporary Art
            </div>
          </Parallax>
        </div>
      </div>
      {!!data?.searchArtworks.artworks.length && (
        <div className="wrapper">
          <div className="vote_title">
            <ExplorePageTitle title="Winners" />
            <div className="vote_title__link">
              <Link to="/explore/vote/winners">View more</Link>
            </div>
          </div>
          <WinnersList artworks={data?.searchArtworks.artworks} />
        </div>
      )}

      <div className="wrapper">
        <ExplorePageTitle
          title="Vote"
          questionMark
          myMoca={false}
          shareMark
          tooltipText={<TooltipTextVote />}
        />
      </div>

      <div className="explore_list wrapper">
        {dataVoteArtworks && (
          <InfiniteScroll
            loader
            scrollThreshold={0.7}
            dataLength={dataVoteArtworks.length}
            next={scrollHandler}
            hasMore
          >
            <div ref={widthBlock}>
              <Masonry
                ref={masonryColumns}
                breakpointCols={BREAKPOINT_COLUMNS_OBJ}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {dataVoteArtworks?.map((artwork: any) => {
                  const widthColumn =
                    widthBlock?.current?.offsetWidth / masonryColumns.current?.state?.columnCount -
                    12;
                  const paramsArtwork = artwork.image.medium.width / artwork.image.medium.height;
                  return (
                    <VoteListArtworks
                      key={artwork._id}
                      artwork={artwork}
                      widthColumn={widthColumn}
                      paramsArtwork={paramsArtwork}
                    />
                  );
                })}
              </Masonry>
            </div>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default VoteExplorePage;
