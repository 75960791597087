import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import TextTemplateNotification from "./TextTemplateNotification";
import { artistLink, explore } from "../../route-link";
import NotificationTime from "./NotificationTime";
import { UserRequests } from "../../api-queries/AllRequests";
import NotificationImage from "./NotificationImage";

interface PublicCommentsArtworkProps {
  nameFollower: string;
  userId: string;
  artworkId: string;
  imageSrc: string;
  textComment: string;
  time: Date;
}

const PublicCommentsArtworkNotification: React.FC<PublicCommentsArtworkProps> = ({
  nameFollower,
  userId,
  time,
  imageSrc,
  textComment,
  artworkId,
}) => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const { data: userData } = useQuery(UserRequests.USER_DETAILS, {
    variables: { _id: authUserId },
  });
  const nameUser = `${userData?.user.profile.firstName} ${userData?.user.profile.secondName}`;
  return (
    <div className="notification_like__artwork">
      <TextTemplateNotification
        messageText={`left a comment on artwork uploaded by `}
        nameFollower={nameFollower}
        linkNameFollower={`${explore}${artistLink}/${userId}`}
        nameUser={nameUser}
        linkNameUser={`${explore}${artistLink}/${authUserId}`}
        textComment={textComment}
      />
      <NotificationTime time={time} />
      <Link to={`${explore}/${artworkId}`}>
        <NotificationImage imageSrc={imageSrc} alt="Artwork" />
      </Link>
      <p className="notification_reply">Reply</p>
    </div>
  );
};

export default PublicCommentsArtworkNotification;
