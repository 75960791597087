import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { IParamsId } from "../../components/artist-profile-artworks/utils";
import { USER_ARTWORKS_FOR_VOTE } from "../../api-queries/UserRequests";
import ArtsControllerList from "../../components/arts-controller-list/ArtsControllerList";
import { IArtwork } from "../../types/artwork/artwork";
import { getUniqueArtworks } from "../../components/artworks-explore-list/utils";

const Campaigns = (): JSX.Element => {
  const { id }: IParamsId = useParams();
  const { data, refetch } = useQuery(USER_ARTWORKS_FOR_VOTE, {
    variables: { limit: 50, ownerId: id },
  });
  const [uniqueArtworks, setUniqueArtworks] = useState<(IArtwork | undefined)[] | null>(null);
  useEffect(() => {
    if (data?.artworksCanBeVoted) {
      setUniqueArtworks(getUniqueArtworks(data?.artworksCanBeVoted?.artworks));
    }
  }, [data]);
  return (
    <div className="artworks_found">
      <ArtsControllerList
        showModal
        showVotes
        artworks={uniqueArtworks}
        refetch={refetch}
        title="MOCA Campaigns"
        blockMaps
      />
    </div>
  );
};

export default Campaigns;
