import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Avatar } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import moment from "moment";
import { ArtworksRequests, ExhibitionRequests } from "../../api-queries/AllRequests";
import { IShowExhibitions } from "../../types/exhibitions";
import formatDate from "../../utils/format-date";
import { Calendar } from "../../assets";
import Button from "../../UI/button/Button";
import { useActions } from "../../hooks/useActions";
import { displayDuration } from "../../utils/display-duration";
import { ShowInfoEnum, ShowInfoTabs } from "./ShowInfo.props";
import CommentsListComponent from "../comments-list/CommentsListComponent";
import ScrollToTop from "../scroll-to-top/ScrollToTop";
import AvatarPlaceholder from "../avatar-placeholder/AvatarPlaceholder";
import { artists, explore } from "../../route-link";

import "./ShowInfo.scss";
import ReportButton from "../report-button/ReportButton";
import { reportShow } from "../../api-queries/GraohQlClient";
import { ReportItemTypes } from "../report-button/utils";
import ShareButton from "../share-button/ShareButton";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import ArtworksGrid from "../artworks-grid/ArtworksGrid";

const ShowInfo = (): JSX.Element => {
  const { state }: any = useLocation();

  const { id } = useParams<{ id: string }>();
  const { data, loading, refetch } = useQuery(ExhibitionRequests.FETCH_EXHIBITION, {
    variables: {
      _id: id,
    },
  });
  const [showMore, setShowMore] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(ShowInfoTabs.OurVirtualShow);

  const showInfoRef = useRef<HTMLDivElement>(null);

  const routerLocation = useLocation();

  const exhibition: IShowExhibitions = data?.exhibition;
  const owner = data?.exhibition?.owner;
  const isInstitution = Boolean(owner?.hasInstitution && owner?.institutions?.length);
  const ownerFullName = isInstitution
    ? owner?.institutions[0]?.name
    : `${owner?.profile?.firstName || ""} ${owner?.profile?.secondName || ""}`;
  const hasDuration = !!(exhibition?.duration?.from && exhibition?.duration?.to);
  const hasPermanentHours = hasDuration && moment(exhibition?.duration?.from).year() === 0;
  const ownerId = exhibition?.owner?._id;
  const { data: physicalArts, loading: artworkLoading } = useQuery(
    ArtworksRequests.SEARCH_ARTWORKS_LITE,
    {
      variables: { collectorId: ownerId, type: ["collector", "artist"] },
    },
  );
  const physicalArtsOnLocation = physicalArts?.searchArtworks?.artworks;
  const virtualArtShow = exhibition?.artworks;
  const isUserInstitution = (): boolean => {
    if (!exhibition?.owner) return false;

    const { hasInstitution, institutions } = exhibition?.owner;
    return Boolean(hasInstitution && institutions.length);
  };
  const navigate = useNavigate();
  const { states } = useTypedSelector(reduxState => reduxState.prevStateReducer);
  const { openModal, addPrevState } = useActions();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onShowTabClick = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    setActiveTab(newValue);
  };

  const setPrevState = (): null => {
    if (showInfoRef.current) {
      addPrevState({
        url: routerLocation.pathname,
        state: activeTab === ShowInfoTabs.OurVirtualShow ? virtualArtShow : physicalArtsOnLocation,
        scroll: window.pageYOffset,
        tab: activeTab,
      });
    }
    return null;
  };

  const displayVirtualArt = activeTab === ShowInfoTabs.OurVirtualShow && !!virtualArtShow?.length;
  const displayPhysicalArt =
    activeTab === ShowInfoTabs.PhysicalArtOnLocation &&
    isUserInstitution() &&
    !!physicalArtsOnLocation?.length;

  useEffect(() => {
    if (!exhibition?.description) return;
    if (exhibition?.description.length > 50) setShowMoreButton(true);
  }, [exhibition?.description]);

  useEffect(() => {
    if (virtualArtShow?.length) {
      setActiveTab(ShowInfoTabs.OurVirtualShow);
      return;
    }
    if (isUserInstitution() && physicalArtsOnLocation?.length) {
      setActiveTab(ShowInfoTabs.PhysicalArtOnLocation);
    }
  }, [artworkLoading]);

  useEffect(() => {
    if (!loading) {
      const prevState = states.find(prevSt => prevSt.url === routerLocation.pathname);
      if (prevState) {
        const prevTab = prevState.tab as string;
        const prevScroll = prevState.scroll as number;
        if (showInfoRef.current) {
          window.scrollTo(0, prevScroll);
        }
        setActiveTab(prevTab);
      }
    }
  }, [loading]);

  const submitOrInviteButton = exhibition?.isOpenToSubmitions ? "Submit Artwork" : "Invite";
  const imgUrl = state?.image || exhibition?.image?.medium?.url;
  const goToOwner = (): void => {
    navigate(`/${explore}/${artists}/${ownerId}`);
  };
  const isMyShow = ownerId === localStorage.getItem("myMOCAId");

  return (
    <div className="show_info" ref={showInfoRef}>
      <ScrollToTop />
      <div className="wrapper">
        <div className="show_info__grid">
          <div className="show_info__content">
            <div className="show_info__title_wrapper">
              <h2 className="show_info__title">{state?.name || exhibition?.title}</h2>
              <div className="show_info__report_share">
                <ShareButton />
                {!isMyShow && (
                  <ReportButton
                    itemId={id}
                    className="show_report"
                    reportAction={reportShow}
                    reportItemType={ReportItemTypes.Show}
                  />
                )}
              </div>
            </div>
            {!loading && (
              <>
                <div className="show_info__owner">
                  {owner?.profile?.image?.thumbnail?.url ? (
                    <Avatar
                      src={owner?.profile?.image?.thumbnail?.url}
                      sx={{ width: 50, height: 50 }}
                      onClick={goToOwner}
                    />
                  ) : (
                    <AvatarPlaceholder
                      onClick={goToOwner}
                      id={id}
                      width="50px"
                      height="50px"
                      fontSize="12px"
                    />
                  )}
                  <div className="show_info__owner__content">
                    <div>
                      <div onClick={goToOwner} className="show_info__owner__name">
                        {ownerFullName}
                      </div>
                      <div className="show_info__owner__date">
                        Likes {exhibition?.owner?.likedArtworks}
                      </div>
                    </div>
                    <Button onClick={openModal} variant="outlined" children="Send Message" />
                  </div>
                </div>
                {exhibition?.isOpenToSubmitions && (
                  <h3 className="show_info__titles">Open To Submission</h3>
                )}
                <div className="show_info__permanent show_info__titles">
                  {displayDuration(exhibition, hasDuration, hasPermanentHours)}
                  {hasDuration && (
                    <div className="show_info__duration">
                      {`${formatDate(exhibition?.duration?.from, "h:mm a")} - ${formatDate(
                        exhibition?.duration?.to,
                        "h:mm a",
                      )}`}
                    </div>
                  )}
                </div>
                <div className="show_info__location show_info__titles">
                  {exhibition?.location?.name}
                </div>
                {!!exhibition?.daysOpen.length && (
                  <div className="calendar_grid">
                    <Calendar />
                    <div className="calendar_days">
                      {exhibition?.daysOpen.map(day => {
                        return (
                          <div className="calendar_duration" key={day.dayOfWeek}>
                            <span>{day.dayOfWeek}</span>{" "}
                            <span>{` ${formatDate(day.hoursOpen.from, "h:mm a")} - ${formatDate(
                              day.hoursOpen.to,
                              "h:mm a",
                            )}`}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="show_info__submit">
                  <Button onClick={openModal} color="dark" children={submitOrInviteButton} />
                </div>
                {exhibition?.description && (
                  <div className="show_info__about">
                    <h3 className="show_info__titles">About</h3>
                    <p className="show_info__description">
                      {showMore ? exhibition.description : exhibition.description.substring(0, 50)}{" "}
                      {showMoreButton && (
                        <>
                          {!showMore && ShowInfoEnum.points}
                          <Button
                            onClick={() => setShowMore(prev => !prev)}
                            children={showMore ? "Show less" : "Show more"}
                            variant="text"
                          />
                        </>
                      )}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>

          {imgUrl && (
            <div className="show_info__image__wrap">
              <img className="show_info__image" src={imgUrl} alt={exhibition?.title} />
            </div>
          )}
        </div>
        {!loading && (
          <>
            <div className="show_info__comments">
              <CommentsListComponent
                type="exhibition"
                refetch={refetch}
                comments={exhibition?.comments || []}
              />
            </div>
            {activeTab && (
              <Tabs className="show_info__tabs" value={activeTab} onChange={onShowTabClick}>
                {!!virtualArtShow?.length && (
                  <Tab
                    className="show_info__tab_item"
                    label={ShowInfoTabs.OurVirtualShow}
                    value={ShowInfoTabs.OurVirtualShow}
                  />
                )}
                {isUserInstitution() && !!physicalArtsOnLocation?.length && (
                  <Tab
                    className="show_info__tab_item"
                    label={ShowInfoTabs.PhysicalArtOnLocation}
                    value={ShowInfoTabs.PhysicalArtOnLocation}
                  />
                )}
              </Tabs>
            )}
            {displayVirtualArt && (
              <div className="show_info__artworks_grid">
                <ArtworksGrid saveState={setPrevState} artworks={virtualArtShow} />
              </div>
            )}
            {displayPhysicalArt && (
              <div className="show_info__artworks_grid">
                <ArtworksGrid saveState={setPrevState} artworks={physicalArtsOnLocation} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default ShowInfo;
