import React from "react";
import { Link } from "react-router-dom";
import formatDate from "../../utils/format-date";

interface LikeNotificationProps {
  nameFollower: string;
  textComment: string;
  time: Date;
}

const LikeNotification: React.FC<LikeNotificationProps> = ({
  nameFollower,
  time,
  textComment,
}): JSX.Element => {
  return (
    <div className="like_notification">
      <div className="notification_text">
        <Link className="notification_link" to="/">
          {nameFollower}
        </Link>{" "}
        liked comment "{textComment}"
      </div>
      <p className="follow_notification__time">{formatDate(time, "MMM DD YYYY")}</p>
    </div>
  );
};

export default LikeNotification;
