import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useActions } from "../../hooks/useActions";
import { IUserProfile, UserItemProrsOwner } from "./userTypes";
import Button from "../../UI/button/Button";
import { artists, explore } from "../../route-link";

import AvatarPlaceholder from "../avatar-placeholder/AvatarPlaceholder";
import { toggleBlockUser, toggleFollowing } from "../../api-queries/GraohQlClient";
import { getMyFollowings, useGetMe } from "../../hooks/useGetMe";

import "./UserListItem.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";

interface IUserListItem {
  profile: IUserProfile;
  id: string;
  likedArtworks?: number;
  ownerLabel?: string;
  owner?: UserItemProrsOwner;
  // eslint-disable-next-line react/no-unused-prop-types
  likes?: number | undefined;
  isInstitution?: boolean;
  institutionName?: string | null;
  saveState?: () => void;
  transferModal?: boolean;
  onClick?: () => void;
  blockedUser?: boolean;
  refetch?: () => void;
}

const UserListItem: React.FC<IUserListItem> = ({
  profile,
  id,
  likedArtworks,
  ownerLabel,
  owner,
  likes,
  isInstitution,
  institutionName,
  saveState,
  transferModal,
  onClick,
  blockedUser,
  refetch,
}) => {
  const { me, updateAuthUser } = useGetMe();
  const { id: paramId } = useParams();
  const { user } = useTypedSelector(state => state.userReducer);
  const isMe = me?._id === id;
  const token = localStorage.getItem("tokenKey");
  const [isFollow, setIsFollow] = useState<boolean>();
  const ownerInstitutionName = owner?.institutions[0]?.name;

  const firstName = profile?.firstName;
  const middleName = profile?.middleName;
  const secondName = profile?.secondName;
  const image = profile?.image;
  const address = profile?.address;

  const fullName = owner?.hasInstitution
    ? ownerInstitutionName
    : `${firstName} ${middleName || ""} ${secondName}`;
  const { openModalLogin, setUser, openModal } = useActions();
  const userLikes = likedArtworks ?? "";
  const userInfo = userLikes ? `Likes ${userLikes}` : address?.city;
  const navigate = useNavigate();
  const goToProfile = (): void => {
    if (onClick) {
      onClick();
      return;
    }
    if (isInstitution) {
      openModal();
      return;
    }
    if (saveState) saveState();
    navigate(`/${explore}/${artists}/${id}`, { state: { name: fullName, image } });
    window.scroll(0, 0);
  };

  const toggleFollowUser = async (): Promise<void> => {
    if (user && isFollow && me?._id === paramId) {
      setUser({ ...user, myFollowings: user?.myFollowings - 1 });
    }
    if (user && !isFollow && me?._id === paramId) {
      setUser({ ...user, myFollowings: user?.myFollowings + 1 });
    }
    setIsFollow(!isFollow);
    await toggleFollowing({ userId: id });
    updateAuthUser();
  };

  const onFollowClick = token ? toggleFollowUser : openModalLogin;

  useEffect(() => {
    const myFollows = me && getMyFollowings(me);
    setIsFollow(Boolean(myFollows?.find((item: { id: string }) => item.id === id)));
  }, [me]);

  const handleUnblockUser = async (): Promise<void> => {
    await toggleBlockUser({ userId: id });
    if (refetch) refetch();
  };

  return (
    <div className="artist_item">
      {image?.medium.url ? (
        <Avatar
          onClick={goToProfile}
          className="artist_item__avatar_icon"
          alt="Remy Sharp"
          src={image?.medium.url}
        />
      ) : (
        <AvatarPlaceholder
          onClick={goToProfile}
          id={id}
          fontSize="12px"
          width="45px"
          height="45px"
          className="artist_item__avatar_icon"
        />
      )}
      <div className="artist_item__description">
        <div className="artist_item__info">
          <div onClick={goToProfile} className="artist_item__name">
            {ownerLabel && <div className="artist_item__name_label">{ownerLabel}:</div>}
            {isInstitution ? institutionName : fullName}
          </div>
          <div className="artist_item__bottom_info">{userInfo}</div>
        </div>
        {likes && <span className="likes">likes: {likes}</span>}
        {blockedUser && (
          <Button
            className="artist_item__block_button"
            children={<span>Unblock</span>}
            onClick={handleUnblockUser}
            variant="text"
          />
        )}
        {!isMe && !transferModal && !blockedUser && (
          <Button
            className="artist_item__follow_button"
            children={<span>{isFollow ? "Following" : "Follow"}</span>}
            onClick={onFollowClick}
            variant="text"
          />
        )}
      </div>
    </div>
  );
};

export default UserListItem;
