import { combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import tokenKeyReducer from "./reducers/token-key.reducer";
import modalReducer from "./reducers/modal.reducer";
import filterReducer from "./reducers/filter.reducer";
import loginReducer from "./reducers/login.reducers";
import profileReducer from "./reducers/profile.reducers";
import headerFlagReducer from "./reducers/header-flag.reducer";
import uploadImagesReducer from "./reducers/upload-image.reducer";
import currentLocationReducer from "./reducers/currentLocation.reducer";
import authorizedUserReducer from "./reducers/authorized-user.reducer";
import refetchReducer from "./reducers/refetch.reducer";
import artworkDetailsReducer from "./reducers/artwork-details.reducer";
import userReducer from "./reducers/user.reducer";
import commentsReducer from "./reducers/comments.reducer";
import userLikedArtworksReducer from "./reducers/user-liked-artworks.reducer";
import userVotedArtworksReducer from "./reducers/user-voted-artworks.reduser";
import buttonUpReducer from "./reducers/button-up";
import searchReducer from "./reducers/search.reducer";
import { artworkSearchFilterReducer } from "./reducers/artwork-search-filter";
import { artistsSearchReducer } from "./reducers/artists-search";
import { institutionSearchReducer } from "./reducers/institution-search.reducer";
import { showsSearchReducer } from "./reducers/shows-search.reducer";
import { artworksScanFilterReducer } from "./reducers/artworks-scan";
import { collectorsSearchReducer } from "./reducers/collectors-search";
import { operatorsSearchReducer } from "./reducers/operators-search.reducer";
import { backUrlReducer } from "./reducers/back-url.reducer";
import { prevStateReducer } from "./reducers/previous-state.reducer";

const reducers = combineReducers({
  tokenKeyReducer,
  modalReducer,
  filterReducer,
  loginReducer,
  profileReducer,
  headerFlagReducer,
  uploadImagesReducer,
  currentLocationReducer,
  authorizedUserReducer,
  refetchReducer,
  artworkDetailsReducer,
  userReducer,
  commentsReducer,
  userLikedArtworksReducer,
  userVotedArtworksReducer,
  buttonUpReducer,
  searchReducer,
  artworkSearchFilterReducer,
  artistsSearchReducer,
  collectorsSearchReducer,
  institutionSearchReducer,
  showsSearchReducer,
  artworksScanFilterReducer,
  operatorsSearchReducer,
  backUrlReducer,
  prevStateReducer,
});

export type RootState = ReturnType<typeof reducers>;

const store = createStore(reducers, composeWithDevTools());

export default store;
