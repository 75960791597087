import React, { FC, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery } from "@apollo/client";
import Masonry from "react-masonry-css";
import { useParams } from "react-router-dom";

import { USER_VOTES } from "../../api-queries/UserRequests";
import BackButton from "../../components/back-button/BackButton";
import { BREAKPOINT_COLUMNS_OBJ } from "../../misc/constants";
import { IArtwork } from "../../types/artwork/artwork";
import ArtworkItem from "../../components/artwork-item/ArtworkItem";

import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { UserVotesArtworksData } from "./utils";

import "./ProfileVotedArtworksPage.scss";
import LeafletMap from "../../components/leaflet-maps/LeafletMap";
import { pluralize } from "../../utils/pluralizeWord";

interface IProfileVotedArtworksPage {
  title?: string;
  controlsPage?: boolean;
}

const ProfileVotedArtworksPage: FC<IProfileVotedArtworksPage> = ({ title, controlsPage }) => {
  const limit = 20;
  const { userVotedArtworks, offset, userId } = useTypedSelector(
    store => store.userVotedArtworksReducer,
  );
  const { setUserVotedArtworks, setVotedArtworksOffset, setUserId, openModal } = useActions();

  const { id } = useParams();
  const { data, fetchMore, refetch, loading } = useQuery(USER_VOTES, {
    variables: { userId, limit },
  });

  const masonryColumns: any = useRef();
  const widthBlock: any = useRef();

  const votedArtworks: IArtwork[] = data?.favorites?.artworks;
  const showEmptyTitle = Boolean(!votedArtworks?.length && !loading);
  const total = votedArtworks?.length;

  const onScroll = async (): Promise<void> => {
    setVotedArtworksOffset(offset + limit);
    const newData: UserVotesArtworksData = await fetchMore({
      variables: { offset: offset + limit },
    });
    setUserVotedArtworks([...userVotedArtworks, ...newData.data.favorites.artworks]);
  };

  useEffect(() => {
    if (userId !== id) {
      setUserVotedArtworks([]);
      setUserId(id || "");
      setVotedArtworksOffset(0);

      refetch();
    }
  }, [id]);

  useEffect(() => {
    if (!userVotedArtworks?.length) {
      setUserId(id || "");
      setUserVotedArtworks(votedArtworks);
    }
  }, [votedArtworks]);
  return (
    <div className="wrapper profile-artworks-page profile-voted-page">
      <h3 className="profile-artworks-page__title">
        <BackButton className="profile-artworks-page__back-button" />
        {title}
      </h3>
      {controlsPage && (
        <LeafletMap
          locations={votedArtworks}
          zoom={3}
          location={undefined}
          exhibitions={undefined}
          block
        />
      )}
      <div className="total">
        {!!total && (
          <span className="filter_results__result">
            {total} {pluralize("Artwork", total)}
          </span>
        )}
      </div>
      {userVotedArtworks && (
        <InfiniteScroll
          scrollThreshold={0.7}
          dataLength={userVotedArtworks?.length}
          next={onScroll}
          hasMore
          loader
        >
          <div ref={widthBlock}>
            <Masonry
              ref={masonryColumns}
              breakpointCols={BREAKPOINT_COLUMNS_OBJ}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {userVotedArtworks?.map(artwork => (
                <div key={artwork._id} onClick={openModal}>
                  <ArtworkItem artwork={artwork} masonryColumns widthBlock />
                </div>
              ))}
            </Masonry>
          </div>
          {showEmptyTitle && (
            <p className="profile-artworks-page__no-artworks">There are no artworks.</p>
          )}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default ProfileVotedArtworksPage;
