import React from "react";
import { Link } from "react-router-dom";
import { artistLink, artists, explore } from "../../route-link";
import NotificationTime from "./NotificationTime";
import NotificationImage from "./NotificationImage";

interface SubmitArtworkNotificationProps {
  nameFrom: string;
  userId: string;
  imageSrc: string;
  artworkId: string;
  time: Date;
}

const SubmitArtworkNotification: React.FC<SubmitArtworkNotificationProps> = ({
  nameFrom,
  userId,
  time,
  imageSrc,
  artworkId,
}): JSX.Element => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  return (
    <div className="notification_grid">
      <p className="notification_text">
        <Link className="notification_link" to={`${explore}/${artists}/${userId}`}>
          {nameFrom}
        </Link>{" "}
        submitted a suggestion for artwork uploaded by{" "}
        <Link className="notification_link" to={`${explore}/${artistLink}/${authUserId}`}>
          You
        </Link>
      </p>
      <NotificationTime time={time} />
      <Link to={`${explore}/${artworkId}`}>
        <NotificationImage imageSrc={imageSrc} alt="Artwork" />
      </Link>
    </div>
  );
};

export default SubmitArtworkNotification;
