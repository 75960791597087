/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { RECOVER_PASSWORD, SIGN_UP } from "./RegistrationRequests";
import {
  ME,
  REMOVE_USER,
  TOGGLE_BLOCK_USER,
  TOGGLE_FOLLOWING,
  UPDATE_USER,
  USER,
} from "./UserRequests";
import { ADD_INSTITUTION } from "./InstitutionRequests";
import { SEARCH_NOTIFICATIONS_ACTIONS } from "./NotificationRequests";
// eslint-disable-next-line import/named
import {
  ADD_SUGGESTION_TO_ARTWORK,
  ARTWORKS_FOR_VOTE,
  DECLINE_ARTWORK_TRANSFER,
  ACCEPT_ARTWORK_TRANSFER,
  REMOVE_ARTWORK_ADDITIONAL_IMAGE,
  WINNERS,
  UPLOAD_ADDITIONAL_PHOTO,
  CHANGE_COLLECTOR,
} from "./ArtworksRequests";
import {
  ArtworksRequests,
  ExhibitionRequests,
  InstitutionRequests,
  UserRequests,
} from "./AllRequests";
import { EDIT_ARTWORK } from "./request-params/ArtworkRequestParams";
import { FETCH_BLOCKED_USERS } from "./request-params/UserProfileRequestParams";
import { ADD_POST } from "./PostRequest";
import { SEARCH_ARTWORKS, SEARCH_INSTITUTIONS } from "./SearchRequests";

const request = (query, variables = {}, files = []) => {
  const BEARER = localStorage.getItem("tokenKey");
  const formData = new FormData();
  formData.append("query", query);
  formData.append("variables", JSON.stringify(variables));
  files.forEach(file => {
    formData.append(file.name, file.data);
  });
  const cfg = {
    method: "POST",
    headers: {
      "x-access-token": BEARER,
    },
    body: formData,
  };
  if (!BEARER) {
    delete cfg.headers;
  }
  return fetch(process.env.REACT_APP_BASE_URL, cfg);
};

export function updateUser(args, profileImage, bannerImage) {
  return request(UPDATE_USER, args, [
    {
      name: "image",
      data: profileImage,
      filename: "photo.jpeg",
      type: "image/jpeg",
    },
    {
      name: "bannerImage",
      data: bannerImage,
      filename: "photo.jpeg",
      type: "image/jpeg",
    },
  ]).then(data => {
    return data.updateUser;
  });
}

export function updateProfile(args, profileImage, bannerImage) {
  return request(UserRequests.UPDATE_PROFILE, args, [
    {
      name: "image",
      data: profileImage,
      filename: "photo.jpeg",
      type: "image/jpeg",
    },
    {
      name: "bannerImage",
      data: bannerImage,
      filename: "photo.jpeg",
      type: "image/jpeg",
    },
  ]).then(data => {
    return data.updateUser;
  });
}

export function addInstitution(args) {
  return request(ADD_INSTITUTION, args);
}

export function createUser(args, profileImage, bannerImage) {
  return request(SIGN_UP, args, [
    { name: "image", data: profileImage },
    { name: "bannerImage", data: bannerImage },
  ]).then(data => data);
}

export function fetchNotification(args) {
  return request(SEARCH_NOTIFICATIONS_ACTIONS, args).then(res => res.json());
}

export function sendRecoveryToken(args) {
  return request(RECOVER_PASSWORD, args).then(data => data);
}

export function addSuggestionToArtwork(args) {
  return request(ADD_SUGGESTION_TO_ARTWORK, args).then(data => data);
}

export async function toggleFollowing(args) {
  const data = await request(TOGGLE_FOLLOWING, args);
  const json = await data.json();
  return json.data.toggleFollowing;
}

export async function fetchMe() {
  const data = await request(ME);
  const json = await data.json();
  return json.data.me;
}

export async function toggleArtworkLike(artworkId) {
  const data = await request(ArtworksRequests.TOGGLE_ARTWORK_LIKE, { artworkId });
  const json = await data.json();
  return json.data.toggleArtworkLike;
}

export async function fetchLikedArtworksLite() {
  const data = await request(ArtworksRequests.LIKED_ARTWORKS_LITE);
  const json = await data.json();
  return json.data.likedArtworks;
}

export async function fetchLikedArtworksByIdLite(userId) {
  const data = await request(ArtworksRequests.LIKED_ARTWORKS_BY_ID_LITE, { userId });
  const json = await data.json();
  return json.data.likedArtworks;
}

export async function submitArtworkShare(artworkId) {
  const data = await request(ArtworksRequests.SUBMIT_ARTWORK_SHARE, { artworkId });
  const json = await data.json();
  return json.submitArtworkShare;
}

export async function removeSuggestionFromArtwork(args) {
  const data = await request(ArtworksRequests.REMOVE_SUGGESTION_FROM_ARTWORK, args);
  const json = await data.json();
  return json.removeSuggestionFromArtwork;
}

export async function removeArtwork(args) {
  const data = await request(ArtworksRequests.REMOVE_ARTWORK, args);
  const json = await data.json();
  return json.removeArtwork;
}

export async function addExhibitionComment(args) {
  const res = await request(ExhibitionRequests.ADD_EXHIBITION_COMMENT, args);
  const data = await res.json();
  return data;
}

export async function addCommentToArtwork(args) {
  const res = await request(ExhibitionRequests.ADD_ARTWORK_COMMENT, args);
  const data = await res.json();
  return data;
}

export async function replyComment(args) {
  const res = await request(ExhibitionRequests.REPLY_COMMENT, args);
  const data = await res.json();
  return data;
}

export async function toggleLike(query, args) {
  const data = await request(query, args);
  const json = await data.json();
  return json.data;
}

export async function createComment(query, args) {
  const data = await request(query, args);
  const json = await data.json();
  return json.data;
}

export async function removeComment(query, args) {
  const data = await request(query, args);
  const json = await data.json();
  return json.data;
}

export async function updateArtwork(args) {
  const data = await request(EDIT_ARTWORK, args);
  return data.json();
}

export async function reportArtwork(args) {
  const res = await request(ArtworksRequests.REPORT_ARTWORK, args);
  const json = await res.json();
  return json.data;
}

export async function reportShow(args) {
  const res = await request(ExhibitionRequests.REPORT_SHOW, args);
  const json = await res.json();
  return json.data;
}

export async function reportUser(args) {
  const res = await request(UserRequests.REPORT_USER, args);
  const json = await res.json();
  return json.data;
}

export async function getUser(args) {
  const data = await request(USER, args);
  const json = await data.json();
  return json.data.user;
}

export async function fetchBlockedUsers(args) {
  const data = await request(FETCH_BLOCKED_USERS, args);
  const json = await data.json();
  return json.data.blockedUsers;
}

export async function toggleBlockUser(args) {
  const data = await request(TOGGLE_BLOCK_USER, args);
  const json = await data.json();
  return json.data;
}

export async function artworksForVote(args) {
  const data = await request(ARTWORKS_FOR_VOTE, args);
  const json = await data.json();
  return json.data.artworksCanBeVoted;
}

export async function getWinnerList() {
  const data = await request(WINNERS);
  const json = await data.json();
  return json.data.searchArtworks;
}

export async function addArtwork({ photoBlob, ...args }) {
  const image = {
    name: "image",
    filename: "photo.jpeg",
    type: "image/jpeg",
    data: photoBlob,
  };
  const data = await request(ArtworksRequests.ADD_ARTWORK, args, [image]);
  const json = await data.json();
  return json.data.addArtwork;
}

export async function editInstitution(args) {
  return request(InstitutionRequests.EDIT_INSTITUTION, args);
}

export async function addPost(args, images) {
  const data = await request(
    ADD_POST,
    args,
    images.map(image => ({
      name: "imagesOfPost",
      filename: "photo.jpeg",
      type: "image/jpeg",
      data: image.url,
    })),
  );
  return data.json();
}

export async function search(query, args) {
  const res = await request(query, args);
  const dataSearch = await res.json();
  return dataSearch.data;
}

export async function searchArtists(query, args) {
  const res = await request(query, args);
  const dataSearch = await res.json();
  return dataSearch.data;
}

export async function searchInstitution(args) {
  const res = await request(SEARCH_INSTITUTIONS, args);
  const dataSearch = await res.json();
  return dataSearch.data;
}

export async function searchArtworks(args) {
  const res = await request(SEARCH_ARTWORKS, args);
  const dataSearch = await res.json();
  return dataSearch.data;
}

export async function artworkDeatils(args) {
  const res = await request(ArtworksRequests.ARTWORK_DETAILS, args);
  const dataSearch = await res.json();
  return dataSearch.data;
}

export async function deleteAdditionalImage(args) {
  const res = await request(REMOVE_ARTWORK_ADDITIONAL_IMAGE, args);
  const dataSearch = await res.json();
  return dataSearch.data;
}

export async function addAdditionalImage(imageUri) {
  const imageBlob = await fetch(imageUri).then(res => res.blob());
  const photo = {
    name: "image",
    filename: "photo.jpeg",
    type: "image/jpeg",
    data: imageBlob,
  };
  const res = await request(UPLOAD_ADDITIONAL_PHOTO, {}, [photo]);
  const json = await res.json();
  return json.data.uploadAdditionalImage;
}

export async function declineArtworkTransfer(args) {
  const res = await request(DECLINE_ARTWORK_TRANSFER, args);
  const dataSearch = await res.json();
  return dataSearch.data;
}

export async function acceptArtworkTransfer(args) {
  const res = await request(ACCEPT_ARTWORK_TRANSFER, args);
  const dataSearch = await res.json();
  return dataSearch.data;
}

export async function changeCollector(images, args) {
  const additionalImages = [];
  if (images.length > 0) {
    images.forEach(image => {
      additionalImages.push({
        name: "additionalImages",
        filename: "photo.jpeg",
        type: "image/jpeg",
        data: image,
      });
    });
  }
  const res = await request(CHANGE_COLLECTOR, args, additionalImages);
  const json = await res.json();
  return json;
}

export async function removeUser(args) {
  const res = await request(REMOVE_USER, args);
  const dataSearch = await res.json();
  return dataSearch.data;
}
