import { useQuery } from "@apollo/client";
import React, { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { USER_SCANS } from "../../api-queries/UserRequests";
import { IParamsId } from "../../components/artist-profile-artworks/utils";
import BackButton from "../../components/back-button/BackButton";
import LeafletMap from "../../components/leaflet-maps/LeafletMap";
import { getScanArtworks } from "./utils";
import { getUniqueArtworks } from "../../components/artworks-explore-list/utils";

import "./ProfileScansPage.scss";
import Button from "../../UI/button/Button";
import ShareButton from "../../components/share-button/ShareButton";
import { artworks, explore, institutions } from "../../route-link";
import { useArtworksSldrCntx } from "../../context/artworksSldrCntx";

const ProfileScansPage: FC = () => {
  const { id: userId }: IParamsId = useParams();
  const navigate = useNavigate();
  const { setArtworks: setSldrAtrwrs } = useArtworksSldrCntx();
  // limit is 500 because of artworks will overlap each other
  const { data } = useQuery(USER_SCANS, { variables: { userId, limit: 500 } });
  const scanArtworks = useMemo(() => {
    if (data) {
      const artworksArray = getScanArtworks(data);
      return getUniqueArtworks(artworksArray);
    }
    return [];
  }, [data]);

  const goToArtworksPage = (): void => navigate(`/${explore}/${artworks}`);
  const goToInstitutionsPage = (): void => navigate(`/${explore}/${institutions}`);

  const saveState = (): void => {
    setSldrAtrwrs(scanArtworks.map((el: any) => ({ id: el._id, artwork: null })));
  };

  return (
    <div className="wrapper profile-artworks-page profile-scans-page">
      <h3 className="profile-artworks-page__title">
        <BackButton className="profile-artworks-page__back-button" />
        Maps
        <ShareButton />
      </h3>

      <div className="profile-scans-page__buttons">
        <Button
          onClick={goToArtworksPage}
          className="profile-scans-page__button"
          children="See Artworks"
          color="dark"
        />
        <Button
          onClick={goToInstitutionsPage}
          className="profile-scans-page__button"
          children="Search Institutions"
          color="dark"
        />
      </div>
      <LeafletMap
        className="profile-scans-page__map"
        locations={scanArtworks}
        zoom={3}
        location={null}
        exhibitions={null}
        saveState={saveState}
      />
    </div>
  );
};

export default ProfileScansPage;
