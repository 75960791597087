import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { artistLink, explore } from "../../route-link";
import { UserRequests } from "../../api-queries/AllRequests";
import NotificationTime from "./NotificationTime";

interface ReplyCommentsNotificationProps {
  nameFrom: string;
  userId: string;
  textComment: string;
  time: Date;
}

const ReplyCommentsNotification: React.FC<ReplyCommentsNotificationProps> = ({
  nameFrom,
  userId,
  time,
  textComment,
}): JSX.Element => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const { data: userData } = useQuery(UserRequests.USER_DETAILS, {
    variables: { _id: authUserId },
  });
  const nameOf = `${userData?.user.profile.firstName} ${userData?.user.profile.secondName}`;
  return (
    <div>
      <p className="notification_text">
        <Link className="notification_link" to={`${explore}${artistLink}/${userId}`}>
          {nameFrom}
        </Link>{" "}
        replied: "Reply" to{" "}
        <Link className="notification_link" to={`${explore}${artistLink}/${authUserId}`}>
          {nameOf}
        </Link>
        : "{textComment}"
      </p>
      <NotificationTime time={time} />
    </div>
  );
};

export default ReplyCommentsNotification;
