import React, { FC, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery } from "@apollo/client";
import Masonry from "react-masonry-css";
import { useLocation, useParams } from "react-router-dom";

import { USER_LIKED_ARTWORKS } from "../../api-queries/UserRequests";
import BackButton from "../../components/back-button/BackButton";
import { BREAKPOINT_COLUMNS_OBJ } from "../../misc/constants";
import { IArtwork } from "../../types/artwork/artwork";
import ArtworkItem from "../../components/artwork-item/ArtworkItem";

import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { LikedArtworksData } from "./utils";
import { useArtworksSldrCntx } from "../../context/artworksSldrCntx";
import { getUniqueArtworks } from "../../components/artworks-explore-list/utils";

const ProfileLikedArtworksPage: FC = () => {
  const limit = 20;
  const { userLikedArtworks, offset, userId } = useTypedSelector(
    store => store.userLikedArtworksReducer,
  );
  const { setUserLikedArtworks, setLikedArtworksOffset, setUserId } = useActions();
  const { setArtworks: setSldrAtrwrs } = useArtworksSldrCntx();
  const routerLocation = useLocation();

  const { id } = useParams();
  const { data, fetchMore, refetch, loading } = useQuery(USER_LIKED_ARTWORKS, {
    variables: { userId, limit },
  });

  const masonryColumns: any = useRef();
  const widthBlock: any = useRef();

  const likedArtworks: IArtwork[] = data?.likedArtworks?.artworks;
  const showEmptyTitle = Boolean(!likedArtworks?.length && !loading);

  const onScroll = async (): Promise<void> => {
    setLikedArtworksOffset(offset + limit);
    const newData: LikedArtworksData = await fetchMore({
      variables: { offset: offset + limit },
    });
    const uniqueArtworks = getUniqueArtworks([
      ...userLikedArtworks,
      ...newData.data.likedArtworks.artworks,
    ]) as IArtwork[];
    setUserLikedArtworks(uniqueArtworks);
  };

  useEffect(() => {
    if (userId !== id) {
      setUserLikedArtworks([]);
      setUserId(id || "");
      setLikedArtworksOffset(0);

      refetch();
    }
  }, [id]);

  useEffect(() => {
    const prevLoadingStateJSON = localStorage.getItem("prevState");
    const prevLoadingState = prevLoadingStateJSON ? JSON.parse(prevLoadingStateJSON) : null;

    if (prevLoadingState?.url === routerLocation.pathname) {
      setUserLikedArtworks(prevLoadingState.state);
      setTimeout(() => window.scroll(0, prevLoadingState.scroll), 500);
      localStorage.removeItem("prevState");
      return;
    }

    if (!userLikedArtworks?.length) {
      setUserId(id || "");
      const uniqueArtworks = getUniqueArtworks(likedArtworks) as any;
      if (uniqueArtworks) setUserLikedArtworks(uniqueArtworks);
    }
  }, [likedArtworks]);

  const saveState = (): void => {
    localStorage.setItem(
      "prevState",
      JSON.stringify({
        state: userLikedArtworks,
        url: routerLocation.pathname,
        scroll: window.scrollY,
      }),
    );
    setSldrAtrwrs(userLikedArtworks.map((el: any) => ({ id: el._id, artwork: null })));
  };

  return (
    <div className="wrapper profile-artworks-page">
      <h3 className="profile-artworks-page__title">
        <BackButton className="profile-artworks-page__back-button" />
        Likes
      </h3>
      {userLikedArtworks && (
        <InfiniteScroll
          scrollThreshold={0.7}
          dataLength={userLikedArtworks?.length}
          next={onScroll}
          hasMore
          loader
        >
          <div ref={widthBlock}>
            <Masonry
              ref={masonryColumns}
              breakpointCols={BREAKPOINT_COLUMNS_OBJ}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {userLikedArtworks?.map(artwork => (
                <ArtworkItem
                  saveState={saveState}
                  key={artwork._id}
                  artwork={artwork}
                  masonryColumns
                  widthBlock
                />
              ))}
            </Masonry>
          </div>
          {showEmptyTitle && (
            <p className="profile-artworks-page__no-artworks">There are no artworks.</p>
          )}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default ProfileLikedArtworksPage;
