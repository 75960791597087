import React, { useEffect, useState } from "react";

import { Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { paperProps } from "../../UI/login-button/LoginButtonMenu.style";
import NotificationItem from "./NotificationItem";
import { fetchNotification } from "../../api-queries/GraohQlClient";
import DefaultNotification from "./DefaultNotification";

import "./Notifications.scss";
import { useActions } from "../../hooks/useActions";

const Notifications = (): JSX.Element => {
  const { openModal } = useActions();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<any[]>([]);
  const open = Boolean(anchorEl);
  // const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  useEffect(() => {
    fetchNotification({
      limit: 500,
    }).then(data => setNotifications(data?.data.actionsNotifications.actions));
  }, []);

  return (
    <div className="notifications">
      <Tooltip title="Notifications" className="notification_list">
        {/* must be handleClick function */}
        <IconButton onClick={openModal} size="small" sx={{ ml: 2 }}>
          <NotificationsIcon className="notification_icon" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          sx: paperProps,
        }}
      >
        {notifications &&
          notifications.map(notice => {
            return (
              <div key={notice._id}>
                <MenuItem className="notice_list__item">
                  <NotificationItem notice={notice} />
                </MenuItem>
                <Divider />
              </div>
            );
          })}
        {!notifications.length && (
          <MenuItem className="notice_list__item">
            <DefaultNotification />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
export default Notifications;
