import React, { useState, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";

import { lengthConvert } from "../../utils/convert";
import { FilterFromToProps, LengthUnits } from "./FilterFromTo.props";
import { IFilterTypes } from "../../types/filterTypes";

import "./FilterFromTo.scss";
import { maxNumber } from "../../misc/constants";

const FilterFromTo: React.FC<FilterFromToProps> = ({
  setArtworks,
  requestField,
  unit,
  typeFilter,
  minValue,
  setMinValue,
  maxValue,
  setMaxValue,
  context,
  priceRange,
}): JSX.Element => {
  const {
    filterResults: { setFilterResults, filterResults },
    filterQueryParams: { filterQueryParams, setfilterQueryParams },
  } = context;

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [errorText, setErrorText] = useState(false);

  const minMaxValue = (): void => {
    if (minValue === undefined && maxValue === undefined) {
      setButtonDisabled(true);
      return;
    }

    if (minValue && !maxValue) {
      setButtonDisabled(false);
      return;
    }

    if (!minValue && maxValue === maxNumber) {
      setButtonDisabled(true);
      return;
    }

    if (minValue && maxValue && maxValue < minValue) {
      setButtonDisabled(true);
      setErrorText(true);
      return;
    }
    setButtonDisabled(false);
    setErrorText(false);
  };

  const min = minValue === undefined ? 0 : minValue;
  const max = maxValue === undefined ? maxNumber : maxValue;

  const resultButtonTitle =
    typeFilter === IFilterTypes.Height || typeFilter === IFilterTypes.Width
      ? `${typeFilter} ${unit} ${min} - ${max}`
      : `${unit} ${min} - ${max}`;

  const prevResult = filterResults.find((result: any) => {
    return result.type === typeFilter;
  });

  useEffect(() => {
    minMaxValue();
  }, [minValue, maxValue]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onSubmitPrice = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    if (prevResult?.max === max && prevResult?.min === min) return;
    setArtworks([]);
    if (prevResult) {
      prevResult.title = `${resultButtonTitle}`;
      prevResult.min = min;
      prevResult.max = max;
      setFilterResults([...filterResults]);
    } else {
      setFilterResults([
        ...filterResults,
        { title: resultButtonTitle, min, max, id: typeFilter, type: typeFilter },
      ]);
    }
    if (unit === LengthUnits.Cm) {
      setfilterQueryParams({
        ...filterQueryParams,
        offset: 0,
        [requestField]: {
          from: lengthConvert(min, LengthUnits.Cm, LengthUnits.In).value,
          to: lengthConvert(max, LengthUnits.Cm, LengthUnits.In).value,
        },
      });
    } else {
      setfilterQueryParams({
        ...filterQueryParams,
        offset: 0,
        [requestField]: {
          from: min,
          to: max,
        },
        ...(priceRange && { forSale: true }),
      });
    }
  };
  return (
    <form onSubmit={onSubmitPrice} className="filter_price">
      <div className="filter_price__content">
        <CurrencyInput
          value={minValue}
          className="filter_price__input"
          id="input-example"
          name="input-name"
          placeholder="Min"
          allowNegativeValue={false}
          step={3}
          allowDecimals={false}
          maxLength={10}
          disableAbbreviations
          onValueChange={value => setMinValue(value ? Number(value) : undefined)}
        />
        to
        <CurrencyInput
          value={maxValue}
          className="filter_price__input"
          id="input-example"
          name="input-name"
          placeholder="Max"
          allowNegativeValue={false}
          step={3}
          allowDecimals={false}
          maxLength={10}
          disableAbbreviations
          onValueChange={value => setMaxValue(value ? Number(value) : undefined)}
        />
      </div>
      {errorText && <p className="filter_error">Minimum must be less than maximum</p>}
      <button disabled={buttonDisabled} className="filter_price__input filter_price__button">
        Apply
      </button>
    </form>
  );
};

export default FilterFromTo;
