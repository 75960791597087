import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useArtworksSldrCntx } from "../../context/artworksSldrCntx";
import { IArtwork } from "../../types/artwork/artwork";

import "./ImageList.scss";

interface IImageList {
  images: IArtwork[];
}

const ImageList: FC<IImageList> = ({ images }) => {
  const { setArtworks: setSldrAtrwrs } = useArtworksSldrCntx();

  const saveArtworks = (): void => {
    setSldrAtrwrs(images.map((el: any) => ({ id: el._id, artwork: null })));
  };
  return (
    <div className="image_list">
      {images.map(artwork => {
        const { url } = artwork.image.medium || artwork.image.thumbnail || artwork.image.origin;
        return (
          <Link
            key={artwork._id}
            onClick={saveArtworks}
            state={artwork}
            to={`/explore/artworks/${artwork._id}`}
          >
            <img src={url} alt={artwork._id} />
          </Link>
        );
      })}
    </div>
  );
};
export default ImageList;
