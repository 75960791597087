import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import ArtsControllerList from "../../components/arts-controller-list/ArtsControllerList";
import { ARTWORK_PENDING_TRANSFER } from "../../api-queries/ArtworksRequests";

import "./IncompleteTransfersPage.scss";
import { IArtwork } from "../../types/artwork/artwork";

const IncompleteTransfersPage = (): JSX.Element => {
  const { data, refetch, loading } = useQuery(ARTWORK_PENDING_TRANSFER, {
    variables: { limit: 50 },
  });

  const [artworks, setArtworks] = useState<(IArtwork | undefined)[] | null>(null);

  useEffect(() => {
    if (data?.artworkPendingTransfers?.artworks) {
      setArtworks(data.artworkPendingTransfers.artworks);
    }
  }, [data]);

  return (
    <div className="incompleted_transfers">
      <ArtsControllerList
        artworks={artworks}
        refetch={refetch}
        title="Pending transfer requests"
        displayFullArtworkInfo
        loading={loading}
      />
    </div>
  );
};

export default IncompleteTransfersPage;
