import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import formatDate from "../../utils/format-date";
import { UserRequests } from "../../api-queries/AllRequests";
import { artistLink, explore } from "../../route-link";
import { NotificationsItemProps } from "./Notifications.props";

const FollowNotification: React.FC<NotificationsItemProps> = ({
  nameFollower,
  time,
  userId,
}): JSX.Element => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const { data: userData } = useQuery(UserRequests.USER_DETAILS, {
    variables: { _id: authUserId },
  });
  const nameUser = `${userData?.user.profile.firstName} ${userData?.user.profile.secondName}`;
  return (
    <div className="follow_notification">
      <p className="follow_notification__text">
        <Link className="notification_link" to={`${explore}${artistLink}/${userId}`}>
          {nameFollower}
        </Link>{" "}
        started following{" "}
        <Link className="notification_link" to={`${explore}${artistLink}/${authUserId}`}>
          {nameUser}
        </Link>
      </p>
      <p className="follow_notification__time">{formatDate(time, "MMM DD YYYY")}</p>
    </div>
  );
};

export default FollowNotification;
