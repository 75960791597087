import React from "react";
import moment from "moment";
import { displayDuration } from "../../utils/display-duration";
import formatDate from "../../utils/format-date";
import Button from "../../UI/button/Button";
import { ArtworkShowsProps } from "./artworkShows.type";
import { useActions } from "../../hooks/useActions";

const ArtworkShows: React.FC<ArtworkShowsProps> = ({
  artworkShows,
  showMoreShows,
  loadMoreShows,
}): JSX.Element => {
  const { openModal } = useActions();
  return (
    <div className="artwork_shows">
      <div className="artwork_shows__item">
        <div className="artwork_shows__wrapper">
          <div className="artwork_shows__grid">
            {artworkShows?.map(card => {
              const hasDuration = !!(card.duration?.from && card.duration?.to);
              const hasPermanentHours = !!(hasDuration && moment(card.duration?.from).year() === 0);
              return (
                // todo when will you make the transition to shows, you need Link
                // <Link key={card._id} to={`/${explore}/${shows}/${card._id}`}>
                <div key={card._id} className="artwork_shows__card" onClick={openModal}>
                  <div className="artwork_shows__image">
                    <img src={card.image?.medium.url} alt={card.title} />
                  </div>
                  <div className="artwork_shows__card__grid">
                    <div className="artwork_shows__label">{card.title}</div>
                    <div className="artwork_shows__sub__title">
                      {displayDuration(card, hasDuration, hasPermanentHours)}
                      {hasPermanentHours && (
                        <span className="institution_card__duration__time">
                          {`${formatDate(card.duration?.from, "h:mm a")} - ${formatDate(
                            card.duration?.to,
                            "h:mm a",
                          )}`}
                        </span>
                      )}
                    </div>
                    <div className="artwork_shows__location">
                      {card.location?.name || "Virtual only"}
                    </div>
                  </div>
                </div>
                // </Link>
              );
            })}
          </div>
          {showMoreShows && (
            <Button onClick={loadMoreShows} variant="outlined" className="load_more">
              Load more shows
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArtworkShows;
