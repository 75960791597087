import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import { UserRequests } from "../../api-queries/AllRequests";
import Share from "../../assets/images/share.svg";
import IconComments from "../../assets/images/iconCommentsBlackThin.svg";
import NotificationTime from "./NotificationTime";

interface LikePostNotificationProps {
  nameFollower: string;
  type: string;
  time: Date;
}

const PostNotification: React.FC<LikePostNotificationProps> = ({
  nameFollower,
  time,
  type,
}): JSX.Element => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const { data: userData } = useQuery(UserRequests.USER_DETAILS, {
    variables: { _id: authUserId },
  });
  const nameUser = `${userData?.user.profile.firstName} ${userData?.user.profile.secondName}`;
  return (
    <div className="notification_like__post">
      <div className="notification_text">
        <Link className="notification_link" to="/">
          {nameFollower}
        </Link>{" "}
        {type}
        <Link className="notification_link" to="/">
          Post
        </Link>
      </div>
      <div className="notification_like__post__body">
        <strong>{nameUser} </strong>
        <span className="notification_time">Date when create post</span>
        <div className="notification_text">Some text post</div>
        <div className="notification_icons">
          <div className="notification_icons__item">
            <FavoriteBorderIcon className="notification_icon" />
          </div>
          <div className="notification_icons__item">
            <img src={IconComments} alt="comments icon" />
          </div>
          <div className="notification_icons__item">
            <img src={Share} alt="share icon" />
          </div>
        </div>
      </div>
      <NotificationTime time={time} />
    </div>
  );
};

export default PostNotification;
