import React from "react";
import { useNavigate } from "react-router-dom";

const ArtworkItem = ({
  artwork,
  masonryColumns,
  widthBlock,
  saveState,
  url = null,
}: any): JSX.Element => {
  const widthColumn =
    widthBlock?.current?.offsetWidth / masonryColumns.current?.state?.columnCount - 12;
  const paramsArtwork = artwork.image.medium.width / artwork.image.medium.height;
  const navigate = useNavigate();

  const openSlider = (): void => {
    if (saveState) saveState();
    navigate(url || `/explore/artworks/${artwork._id}`, { state: artwork });
  };

  return (
    <div
      onClick={openSlider}
      className="artwork_item"
      style={{ minHeight: Math.floor(widthColumn / paramsArtwork) }}
    >
      <img className="artwork_item__image" src={artwork.image.medium.url} alt={artwork.name} />
    </div>
  );
};

export default ArtworkItem;
