import React, { forwardRef } from "react";
import { CommentsProps } from "./Comments.type";
import Comment from "./Comment";

import "./Comments.scss";

/**
 * The Comment Functional Component
 * @typedef Props:
 * @property {function} handleReply - switches to input reply
 * @property {function} refetch - reload data from the server
 * @typedef TextFieldProps
 * @property {string} artworkId - id of artwork
 */

// eslint-disable-next-line react/display-name
const Comments = forwardRef<any, CommentsProps>(
  ({ artworkId, handleReply, refetch, comments }, ref): JSX.Element => {
    return (
      <div className="moca_comments">
        <div className="moca_comments__body">
          {comments.map(comment => (
            <Comment
              key={comment._id}
              comment={comment}
              artworkId={artworkId}
              handleReply={handleReply}
              refetch={refetch}
            />
          ))}
        </div>
        <div ref={ref} />
      </div>
    );
  },
);

export default Comments;
