import React from "react";
import { Link } from "react-router-dom";
import { artistLink, artworks, explore } from "../../route-link";
import NotificationTime from "./NotificationTime";
import NotificationImage from "./NotificationImage";

interface DeclineTransferArtworkNotificationProps {
  nameFrom: string;
  userId: string;
  imageSrc: string;
  artworkId: string;
  nameArtwork: string;
  time: Date;
}

const DeclineTransferArtworkNotification: React.FC<DeclineTransferArtworkNotificationProps> = ({
  nameFrom,
  userId,
  time,
  imageSrc,
  artworkId,
  nameArtwork,
}) => {
  return (
    <div className="notification_grid">
      <p className="notification_text">
        <Link className="notification_link" to={`${explore}${artistLink}/${userId}`}>
          {nameFrom}
        </Link>{" "}
        decline request to transfer artwork "{nameArtwork}"
      </p>
      <NotificationTime time={time} />
      <Link to={`${explore}/${artworks}/${artworkId}`}>
        <NotificationImage imageSrc={imageSrc} alt="Artwork" />
      </Link>
    </div>
  );
};

export default DeclineTransferArtworkNotification;
