// eslint-disable-next-line import/no-cycle
import { IMAGE } from "./ImageRequestParams";
import { LOCATION } from "./LocationRequestParams";

export const ARTWORK = `
_id
name
artistName
createdByArtistYear
minimumBid
votesCount
selectedForExhibition
selectedForVote
saleable
availableOut
owner {
  _id
  hasInstitution
  institutions {
    name
  }
  profile {
    firstName
    middleName
    secondName
    image{
      ${IMAGE}
    }
  }
}
collector {
  _id
  profile {
    firstName
    middleName
    secondName
    image{
      ${IMAGE}
    }
  }
}
image {
  ${IMAGE}
}
type
createdAt
locationLives {
  ${LOCATION}
}
locationFound {
  ${LOCATION}
}
 locationCreated {
  ${LOCATION}
}
locationCollected {
  ${LOCATION}
}`;

export const EDIT_ARTWORK = `
mutation updateArtwork(
  $_id: String!
  $name: String
  $artistName: String
  $yearCreated: String
  $price: Int
  $notes: String
  $history: String
  $inspiration: String
  $locationFound: LocationInputType
  $locationCollected: LocationInputType
  $locationLives: LocationInputType
  $locationCreated: LocationInputType
  $dimensions: DimensionsInput
  $saleable: Boolean
  $availableOutOfMoca: Boolean
  $selectedForVote: Boolean
  $colors: [String]
  $materials: [String]
  $categories: [ID]
  $attachments: [AttachmentInput]
  $conditionReport: String
  $pastExhibitions: [PastExhibitionInput]
  $collectors: [CollectorInput]
  $additionalImages: [ImageInput]
  $type: ArtworkType
  $creationInProgress: Boolean
  $filmsOrTv: [String]
  $hasFilmsOrTv: Boolean
  $allowUseForFilmAndTv: Boolean
  $published: Boolean
) {
  updateArtwork(
    _id: $_id
    name: $name
    artistName: $artistName
    createdByArtistYear: $yearCreated
    minimumBid: $price
    notes: $notes
    history: $history
    inspiration: $inspiration
    locationFound: $locationFound
    locationCollected: $locationCollected
    locationLives: $locationLives
    locationCreated: $locationCreated
    dimensions: $dimensions
    saleable: $saleable
    availableOut: $availableOutOfMoca
    selectedForVote: $selectedForVote
    colors: $colors
    materials: $materials
    categories: $categories
    attachments: $attachments
    conditionReport: $conditionReport
    pastExhibitions: $pastExhibitions
    collectors: $collectors
    additionalImages: $additionalImages
    type: $type
    creationInProgress: $creationInProgress
    filmsOrTv: $filmsOrTv
    hasFilmsOrTv: $hasFilmsOrTv
    allowUseForFilmAndTv: $allowUseForFilmAndTv
    published: $published
  ) {
    _id
name
artistName
createdByArtistYear
minimumBid
votesCount
selectedForExhibition
selectedForVote
saleable
availableOut
notes
history
inspiration
sharesCount
scansCount
published
locationFound {
  ${LOCATION}
}
locationLives {
  ${LOCATION}
}
locationCollected {
  ${LOCATION}
}
locationCreated {
  ${LOCATION}
}
dimensions {
  width
  height
  length
  weight
  metrics
}
owner {
  _id
  email
  myArtworksLikes
  likedArtworks
  profile {
    firstName
    middleName
    secondName
    image{
      ${IMAGE}
    }
  }
  institutions {
    name
    description
  }
  hasInstitution

}
collector {
  _id
  profile {
    firstName
    middleName
    secondName
    image{
      ${IMAGE}
    }
  }
  institutions {
    name
    description
  }
  hasInstitution
}
pastCollectors {
  _id
  myArtworksLikes
  likedArtworks
  profile {
    firstName
    middleName
    secondName
    image{
      ${IMAGE}
    }
  }
  institutions {
    name
    description
  }
  hasInstitution
}
materials
colors
image {
  ${IMAGE}
}
type
createdAt
suggestions {
  _id
  artistName
  artworkTitle
  notes
  iDontKnow
  createdAt
  video {
    _id
    url
    type
    info
  }
  author {
    _id
    institutions {
      name
    }
    hasInstitution
    profile {
      firstName
      middleName
      secondName
      image {
        ${IMAGE}
      }
    }
  }
}
conditionReport
additionalImages {
  ${IMAGE}
}
collectors {
  collectedBy
  yearCollected
  collectedAt {
    ${LOCATION}
  }
}
pastExhibitions {
  exhibition
  year
}
transferIsPending
transferInfo {
  _id
  conditionReport
  tradeFor
  loanTo
  notes
  price
  type
  commission
  allowUseForFilmAndTv
  saleable
  locationLives{
    ${LOCATION}
  }
  locationCollected{
    ${LOCATION}
  }
  filmsOrTv
  hasFilmsOrTv
  images{
    ${IMAGE}
  }
  tradeForValue
  tradeForObject
  availableForLoan
  loantTo {
    name
    date
  }
}
transferHistory {
  _id
  conditionReport
  tradeFor
  loanTo
  notes
  price
  saleable
  type
  commission
  allowUseForFilmAndTv
  locationLives{
    ${LOCATION}
  }
  locationCollected{
    ${LOCATION}
  }
  filmsOrTv
  hasFilmsOrTv
  images{
    ${IMAGE}
  }
  tradeForValue
  tradeForObject
  availableForLoan
  loantTo {
    name
    date
  }
  createdAt
}
transferringTo {
  _id
  hasInstitution
  institutions {
    name
  }
  profile {
    firstName
    middleName
    secondName
    image{
      ${IMAGE}
    }
  }
}
tradeFor
filmsOrTv
hasFilmsOrTv
allowUseForFilmAndTv
tradeForValue
tradeForObject
availableForLoan
iDontKnowCount
winner
  }
}`;
