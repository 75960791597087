import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, TextField } from "@mui/material";
import { useMutation } from "@apollo/client";

import { SIGN_IN } from "../../api-queries/RegistrationRequests";
import { explore } from "../../route-link";
import { useActions } from "../../hooks/useActions";
import RecoverPassword from "../recover-password/RecoverPassword";

import "./Login.scss";
import { getUser } from "../../api-queries/GraohQlClient";

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const { closeModalLogin, login, headerFlag } = useActions();
  const [signInUser] = useMutation(SIGN_IN);
  const [emailLogin, setEmailLogin] = useState("");
  const [passLogin, setPassLogin] = useState("");
  const [userError, setUserError] = useState("");
  const [recoverPassword, setRecoverPassword] = useState(false);
  const onChangeEmailLogin = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailLogin(event.target.value);
  };
  const onChangePassLogin = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassLogin(event.target.value);
  };
  const { setAuthorizedUser } = useActions();

  const updateToken = (token: string, id: string): void => {
    localStorage.setItem("tokenKey", token);
    localStorage.setItem("myMOCAId", id);
    getUser({
      _id: id,
    }).then(setAuthorizedUser);
    headerFlag();
    login();
    closeModalLogin();
    navigate(explore);
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onSubmitForm = async (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    setUserError("");
    try {
      const { data } = await signInUser({
        variables: {
          email: emailLogin,
          password: passLogin,
        },
      });
      updateToken(data?.signIn.token, data?.signIn.me._id);
    } catch {
      setUserError("The password or email is wrong");
    }
  };

  const onRecoverPassword = (value: boolean): void => setRecoverPassword(value);

  const disabled = !emailLogin || !passLogin;

  return (
    <>
      {!recoverPassword && (
        <div className="login">
          <form onSubmit={onSubmitForm} noValidate>
            <TextField
              className="registration_input input"
              label="EMAIL"
              type="email"
              value={emailLogin}
              onChange={onChangeEmailLogin}
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
            />
            <TextField
              className="registration_input input"
              label="PASSWORD"
              type="password"
              value={passLogin}
              onChange={onChangePassLogin}
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
            />
            <div className="forgot_pass">
              <div className="forgot_pass__link" onClick={() => onRecoverPassword(true)}>
                Forgot password
              </div>
            </div>
            <Button disabled={disabled} type="submit" className="login_button" variant="contained">
              Sign In
            </Button>
            {userError && (
              <Alert className="alert_error" severity="error">
                {userError}
              </Alert>
            )}
          </form>
        </div>
      )}
      {recoverPassword && <RecoverPassword onRecoverPassword={onRecoverPassword} />}
    </>
  );
};

export default Login;
