import React from "react";
import { Link } from "react-router-dom";

import { artists, explore } from "../../route-link";
import NotificationTime from "./NotificationTime";
import NotificationImage from "./NotificationImage";

interface TransferNotificationProps {
  nameFrom: string;
  userId: string;
  imageSrc: string;
  time: Date;
}

const TransferNotification: React.FC<TransferNotificationProps> = ({
  time,
  userId,
  nameFrom,
  imageSrc,
}): JSX.Element => {
  return (
    <div className="notification_grid">
      TransferNotification
      <p className="notification_text">
        <Link className="notification_link" to={`${explore}/${artists}/${userId}`}>
          {nameFrom}
        </Link>{" "}
        sent request to <strong> You </strong>to transfer artwork "Name artwork"
      </p>
      <NotificationTime time={time} />
      <NotificationImage imageSrc={imageSrc} alt="" />
    </div>
  );
};

export default TransferNotification;
