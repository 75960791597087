/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-console */
import React, { memo, useEffect, useRef, useState } from "react";
import uniqid from "uniqid";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import cn from "classnames";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useLocation, useNavigate } from "react-router-dom";

import ReactPlayer from "react-player";
import Button from "../../UI/button/Button";
import UserListItem from "../user-list-item/UserListItem";
import { useActions } from "../../hooks/useActions";
import ArtworkDetailsMap from "../artwork-details-map/ArtworkDetailsMap";
import { IArtwork } from "../../types/artwork/artwork";
import { makeOrEditOfferButtonTitle, removeEmptyFields } from "./utils";
import ArtworkIdentify from "../artwork-identify/ArtworkIdentify";
import {
  acceptArtworkTransfer,
  addCommentToArtwork,
  addSuggestionToArtwork,
  declineArtworkTransfer,
  removeArtwork,
  removeSuggestionFromArtwork,
  replyComment,
  submitArtworkShare,
} from "../../api-queries/GraohQlClient";
import DialogWindow from "../dialog-window/DialogWindow";

import { artworks, editArtwork, explore, incomingTransfers } from "../../route-link";
import { ISuggestion } from "../../types/suggestion";
import RenderCopyLink from "../../UI/render-copy-link/RenderCopyLink";
import LikeArtworkButton from "../like-artwork-button/LikeArtworkButton";
import ShareButton from "../share-button/ShareButton";
import ArtworkTabs from "../artwork-tabs/ArtworkTabs";
import { artworkTabsList, artworkTabsListFound } from "../artwork-tabs/artwork-tabs-data";
import TransferHistoryList from "../transfer-history-list/TransferHistoryList";
import { IShowExhibitions } from "../../types/exhibitions";
import ArtworkShows from "../artwork-shows/ArtworkShows";
import Comments from "../../UI/comments/Comments";
import iconComments from "../../assets/images/iconCommentsBlack@3x.png";
import SuggestionItem from "../suggestion-item/SuggestionItem";
import DialogContent from "../../UI/dialog-content/DialogContent";

import "./ExploreItemDetailsRight.scss";
import ExploreItem from "../artworks-explore-item-details/components/ExploreItem";
import { IArtworkCollector } from "../artworks-explore-item-details/ArtWorksExploreItemDetails.type";
import MainInfo from "./MainInfo";
import { IComment } from "../../types/comment";
import TransferDetailsModal from "./TransferDetailsModal/TransferDetailsModal";
import { removeArtworkFromLocalStorage } from "../../utils/removeArtworkFromLocalStorage";
import CreateTransferModal from "./CreateTransferModal/CreateTransferModal";
import EditCollectedArtworkModal from "./EditCollectedArtworkModal/EditCollectedArtworkModal";
import { useCreateTransferContext } from "../../context/createTransferContext";

interface IExploreItemDetailsRight {
  artwork: IArtwork;
  artworkId: string | undefined;
  refetch: () => void;
  suggestions: Array<ISuggestion>;
  showOwnerActionsButtons: boolean;
  showCollectorActionButtons: boolean;
  removeArtworkFromSlider: () => void;
  blockTransferModal: boolean;
  setBlockSliding: (block: boolean) => void;
}

const ExploreItemDetailsRight: React.FC<IExploreItemDetailsRight> = ({
  artwork,
  artworkId,
  refetch,
  suggestions,
  showOwnerActionsButtons,
  showCollectorActionButtons,
  removeArtworkFromSlider,
  blockTransferModal,
  setBlockSliding,
}) => {
  const [comments, setComments] = useState<IComment[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const myRef = useRef<null | HTMLDivElement>(null);
  const inputFocus = useRef<null | HTMLTextAreaElement>(null);
  const artworkDetailsRightSection = useRef<null | HTMLDivElement>(null);
  const commentTypeSelect = useRef<null | HTMLDivElement>(null);
  const initialValue = {
    comment: "",
    reply: "",
  };
  const myMOCAId = localStorage.getItem("myMOCAId");
  const isIncomingTransfer = artwork?.transferringTo?._id === myMOCAId;
  // states
  const [limit, setLimit] = useState(5);
  const [index, setIndex] = useState(limit);
  const [typeComment, setTypeComment] = useState(false);
  const [showTypeComment, setShowTypeComment] = useState(false);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [likesCount, setLikesCount] = useState(artwork?.likesCount);
  const [sharesCount, setSharesCount] = useState(artwork?.sharesCount);
  const [showArtworkIdentify, setShowArtworkIdentify] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(artworkTabsList[0].id);
  const [artworkShows, setArtworkShows] = useState<IShowExhibitions[]>(artwork?.exhibitions);
  const [showMoreShows, setShowMoreShows] = useState(false);
  const [checkedCommentInput, setCheckedCommentInput] = useState<"comment" | "reply">("comment");
  const [commentValue, setCommentValue] = useState(initialValue);
  const [checkCommentId, setCheckCommentId] = useState("");
  const [showCollectorButtons, setShowCollectorButtons] = useState(showCollectorActionButtons);
  const [showOwnerButtons, setShowOwnerButtons] = useState(showOwnerActionsButtons);
  const [showDeclineTransferModal, setShowDeclineTransferModal] = useState(false);
  const [showTransferDetailsModal, setShowTransferDetailsModal] = useState(false);
  const [showEditCollectedArtworkModal, setShowEditCollectedArtworkModal] = useState(false);
  const iDontKnowSuggestions = artwork?.suggestions?.filter(item => item.iDontKnow);

  const findSuggestions = iDontKnowSuggestions?.find(el => el.author._id === myMOCAId);
  const artworkImage = artwork?.image?.medium?.url;

  const { openModal, openModalLogin, removeArtworkFromSpecificState, removeArtworkFromStates } =
    useActions();

  const { showCreateTransferModal, setShowCreateTransferModal } = useCreateTransferContext();

  const displayConditionReport = Boolean(artwork?.conditionReport);
  const displayNotes = Boolean(artwork?.notes);
  const displayInspiration = Boolean(artwork?.inspiration);
  const showTransferButton = Boolean(artwork?.type !== "found");
  const showTransferringTo =
    Boolean(showOwnerActionsButtons || showCollectorActionButtons) &&
    artwork?.transferIsPending &&
    artwork;

  const showBuyButtons = Boolean(
    !showOwnerButtons && !showCollectorButtons && artwork?.saleable && artwork?.minimumBid,
  );
  const handleAddSuggestions = async (): Promise<void> => {
    await addSuggestionToArtwork({
      artworkId,
      iDontKnow: true,
    });
    refetch();
    setShowArtworkIdentify(false);
  };
  const handleRemoveSuggestions = async (): Promise<void> => {
    await removeSuggestionFromArtwork({
      artworkId,
      suggestionId: findSuggestions && findSuggestions._id,
    });
    refetch();
    setShowArtworkIdentify(true);
  };

  const handleDialogClose = (): void => {
    setDialogOpen(false);
  };

  const removeArtFromStates = (): void => {
    if (!artworkId) return;
    removeArtworkFromLocalStorage(artworkId);

    if (location.pathname.includes(incomingTransfers)) {
      removeArtworkFromSpecificState(`/${incomingTransfers}/${myMOCAId}`, artworkId);
      return;
    }
    removeArtworkFromStates(artworkId);
  };

  const handleRemoveArtwork = async (): Promise<void> => {
    try {
      if (!artworkId) return;
      removeArtwork({ artworkId });
      removeArtFromStates();
      handleDialogClose();
      removeArtworkFromSlider();
    } catch (err) {
      console.error(err);
    }
  };

  const pushEditArtwork = async (): Promise<void> => {
    await refetch();
    navigate(`/${explore}/${artworks}/${artworkId}/${editArtwork}`, {
      state: artwork,
    });
  };

  const focusComment = (): void => inputFocus.current?.focus();

  const handleDeclineTransfer = async (): Promise<void> => {
    try {
      await declineArtworkTransfer({
        artworkId,
      });
      removeArtFromStates();
      setShowDeclineTransferModal(false);
      setShowTransferDetailsModal(false);
      await refetch();
      focusComment();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAcceptTransfer = async (): Promise<void> => {
    try {
      await acceptArtworkTransfer({
        artworkId,
      });
      refetch();
      removeArtFromStates();
      setShowCollectorButtons(true);
      if (artwork.owner._id === myMOCAId && artwork.type === "collector") {
        setShowOwnerButtons(true);
        setShowCollectorButtons(false);
      }
      setShowTransferDetailsModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setShowOwnerButtons(showOwnerActionsButtons);
    setShowCollectorButtons(showCollectorActionButtons);
  }, [showOwnerActionsButtons, showCollectorActionButtons]);

  useEffect(() => {
    setShowTransferDetailsModal(false);
    if (
      isIncomingTransfer &&
      artworkId &&
      location.pathname.includes(`${artworkId}/${incomingTransfers}`)
    ) {
      setShowTransferDetailsModal(true);
    }
  }, [location]);

  const openCreateTransferModal = (): void => {
    setShowCreateTransferModal(true);
    setBlockSliding(true);
  };

  const closeCreateTransferModal = (): void => {
    setShowCreateTransferModal(false);
    setBlockSliding(false);
  };

  const openEditCollectedArtworkModal = (): void => {
    setShowEditCollectedArtworkModal(true);
  };

  const ActionButtons: React.FC = () => {
    const transferIsPending = artwork?.transferIsPending;

    const openDeclineTransferModal = (): void => {
      setShowDeclineTransferModal(true);
    };

    const handleTransferButtonClick = (): (() => void) =>
      transferIsPending ? openDeclineTransferModal : openCreateTransferModal;

    if (showBuyButtons)
      return (
        <div className="explore_item_details_right__buy_buttons">
          <Button
            onClick={openModal}
            color="dark"
          >{`Asking $${artwork?.minimumBid.toLocaleString()}`}</Button>
          <Button onClick={openModal} color="dark">
            Make an offer
          </Button>
        </div>
      );
    if (showOwnerButtons) {
      const transferTitle = transferIsPending ? "Decline transfer" : "Transfer";
      return (
        <div
          className={`explore_item_details_right__action_buttons ${
            showTransferButton ? "show_transfer" : ""
          }`}
        >
          {showTransferButton && (
            <Button onClick={handleTransferButtonClick()} color="dark">
              {transferTitle}
            </Button>
          )}
          <Button onClick={pushEditArtwork} color="dark">
            Edit
          </Button>
          <Button onClick={() => setDialogOpen(true)} color="dark">
            Remove
          </Button>
        </div>
      );
    }
    if (showCollectorButtons) {
      const transferTitle = artwork?.transferIsPending ? "Decline transfer" : "Transfer";
      const offerButtonText = makeOrEditOfferButtonTitle(artwork);
      const buttonStyles = offerButtonText.length > 30 ? { fontSize: 13 } : { fontSize: 15 };
      return (
        <div className="explore_item_details_right__collector_buttons">
          <Button onClick={openEditCollectedArtworkModal} style={buttonStyles} color="dark">
            {offerButtonText}
          </Button>
          <Button onClick={handleTransferButtonClick()} color="dark">
            {transferTitle}
          </Button>
        </div>
      );
    }
    return null;
  };

  const suggestionsText = suggestions?.length
    ? "Identify this artwork with more info"
    : "Be first who identifies this artwork";

  const identifyText = (): JSX.Element =>
    iDontKnowSuggestions?.length === 1 ? (
      <>
        <strong>You</strong> can't identify.{" "}
      </>
    ) : (
      <>
        <strong>You and {iDontKnowSuggestions?.length - 1} more</strong> can't identify.{" "}
      </>
    );

  const loadMoreShows = (): void => {
    setArtworkShows(artwork?.exhibitions);
    setShowMoreShows(false);
  };

  const handleTabClick = (id: string): void => {
    setSelectedTabId(id);
  };
  const handleChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setCommentValue({ ...commentValue, [event.target.name]: event.target.value });
  };

  const commentType = checkedCommentInput === "comment";
  const handleAddComment = async (): Promise<void> => {
    await addCommentToArtwork({
      artworkId,
      text: commentValue.comment,
    });

    if (refetch) refetch();
    if (artwork.comments?.length - 1 < limit) {
      setLoadMoreButton(false);
    }
    setLimit(artwork.comments.length + 1);
    setCommentValue(initialValue);
    setShowTypeComment(false);
  };
  const loadMore = (): void => {
    const newIndex = limit + index;
    setComments([...artwork?.comments, ...comments?.slice(index, newIndex)].reverse());
    setIndex(newIndex);
    setLoadMoreButton(false);
  };

  const handleAddReply = async (): Promise<void> => {
    await replyComment({
      commentId: checkCommentId,
      text: commentValue.reply,
    });
    if (refetch) refetch();
    setCommentValue(initialValue);
    setShowTypeComment(false);
    setCheckedCommentInput("comment");
  };

  const handleOnClick = commentType ? handleAddComment : handleAddReply;

  const handleReply = (id: string): void => {
    setCheckCommentId(id);
    setCheckedCommentInput("reply");
    inputFocus.current?.focus();
  };

  const getReverseComments = (): void => {
    setComments([...artwork?.comments].reverse().slice(0, limit));
  };

  useEffect(() => {
    if (artwork) getReverseComments();
    if (artwork?.comments.length > limit) {
      setLoadMoreButton(true);
    }
    setLikesCount(artwork?.likesCount);
    setSharesCount(artwork?.sharesCount);
    setArtworkShows(artwork?.exhibitions);
  }, [artwork]);

  useEffect(() => {
    if (findSuggestions) setShowArtworkIdentify(false);
    if (!findSuggestions) setShowArtworkIdentify(true);
  }, [findSuggestions]);

  useEffect(() => {
    refetch();
  }, [selectedTabId]);

  const hideTypeCommentHandle = (e: MouseEvent): void => {
    if (!commentTypeSelect.current?.contains(e.target as Node)) {
      setShowTypeComment(false);
      setCheckedCommentInput("comment");
      artworkDetailsRightSection.current?.removeEventListener("click", hideTypeCommentHandle);
    }
  };

  const showTypeCommentHandle = (): void => {
    if (!myMOCAId) {
      openModalLogin();
      inputFocus?.current?.blur();
      return;
    }
    artworkDetailsRightSection.current?.addEventListener("click", hideTypeCommentHandle);
    setShowTypeComment(true);
  };

  const activeClassHandler = (toggle: boolean) => () => setTypeComment(toggle);
  const artworkTabs = artwork?.type !== "found" ? artworkTabsList : artworkTabsListFound;
  const filteredPastExhibitions =
    artwork?.pastExhibitions && removeEmptyFields(artwork.pastExhibitions);
  const filteredCollectors = artwork?.collectors && removeEmptyFields(artwork.collectors);
  return (
    <div ref={artworkDetailsRightSection} className="explore_item_details_right">
      <DialogWindow
        open={showDeclineTransferModal}
        handleClose={() => setShowDeclineTransferModal(false)}
        title="Are you sure that transfer should be declined?"
        className="remove"
      >
        <DialogContent
          handleDialogClose={() => setShowDeclineTransferModal(false)}
          handleRemove={handleDeclineTransfer}
        />
      </DialogWindow>
      <EditCollectedArtworkModal
        artworkId={artworkId}
        isOpen={showEditCollectedArtworkModal}
        artwork={artwork}
        close={() => setShowEditCollectedArtworkModal(false)}
        refetch={refetch}
      />
      {isIncomingTransfer && !blockTransferModal && (
        <TransferDetailsModal
          isOpen={showTransferDetailsModal}
          artwork={artwork}
          close={() => setShowTransferDetailsModal(false)}
          acceptTransfer={handleAcceptTransfer}
          declineTransfer={handleDeclineTransfer}
        />
      )}
      {(showOwnerButtons || showCollectorButtons) && !artwork?.transferIsPending && (
        <CreateTransferModal
          artworkId={artworkId}
          close={closeCreateTransferModal}
          isOpen={showCreateTransferModal}
          refetch={refetch}
          initialHasFilmsAndTv={artwork.hasFilmsOrTv}
        />
      )}
      <div className="wrap">
        <DialogWindow
          className="explore_item_details_right__dialog"
          open={dialogOpen}
          handleClose={handleDialogClose}
          title="Do you really want to remove the artwork?"
        >
          <DialogContent handleDialogClose={handleDialogClose} handleRemove={handleRemoveArtwork} />
        </DialogWindow>
        <MainInfo
          artwork={artwork}
          showOwnerActionsButtons={showOwnerButtons}
          showCollectorActionButtons={showCollectorButtons}
        />
        <div className="artworks_action_buttons">
          <div className="artwork_comments_icon" onClick={focusComment}>
            <img src={iconComments} alt="Icon comments" />
            <span className="artwork_scans__comments">
              {artwork?.comments.length.toString()} Comments
            </span>
          </div>
          <div className="artwork_scans">
            <VisibilityOutlinedIcon fontSize="small" className="artwork_scans__img" />
            <span className="artwork_scans__count">
              {`${artwork?.scansCount?.toLocaleString() || ""} Scanned`}
            </span>
          </div>
          <LikeArtworkButton
            artworkId={artworkId}
            likesCount={likesCount}
            setLikesCount={setLikesCount}
            refetch={refetch}
          />
          <div className="artwork_shares">
            <ShareButton
              setSharesCount={setSharesCount}
              submitShare={submitArtworkShare}
              refetch={refetch}
              id={artworkId}
            />
            <span className="artwork_shares_count">{sharesCount?.toLocaleString()} Shared</span>
          </div>
        </div>
        <ActionButtons />
        {!showArtworkIdentify && (
          <>
            <div className="show_artwork__identify__suggestion show_artwork__identify">
              {identifyText()}
              Or ask your friend for suggestion. <RenderCopyLink title="Copy link" />
            </div>
            <div className="show_artwork__identify" onClick={handleRemoveSuggestions}>
              I can identify this artwork now
            </div>
          </>
        )}
        {artwork?.type === "found" && !artwork?.artistName && showArtworkIdentify && (
          <ArtworkIdentify
            artworkImage={artworkImage}
            artworkId={artworkId}
            handleAddSuggestions={handleAddSuggestions}
            suggestionsText={suggestionsText}
            iDontKnowSuggestions={iDontKnowSuggestions}
            refetch={refetch}
          />
        )}

        {isIncomingTransfer && (
          <div className="explore_item_details_right__pending">
            <h4>Pending approval</h4>
            <Button
              children="Show transfer info"
              color="dark"
              onClick={() => setShowTransferDetailsModal(true)}
            />
          </div>
        )}

        {showTransferringTo && (
          <div className="explore_item_details_right__pending">
            <h4>Pending approval</h4>
            <UserListItem
              profile={artwork?.transferringTo?.profile}
              id={artwork?.transferringTo?._id}
              owner={artwork?.owner}
              isInstitution={!!artwork?.transferringTo?.institutions.length}
              institutionName={artwork?.transferringTo?.institutions[0]?.name}
            />
          </div>
        )}

        {!!suggestions?.length && (
          <div className="artwork_suggestions">
            <h3 className="artwork_suggestions__title">{suggestions?.length} Suggestions</h3>
            {suggestions?.map(suggestion => (
              <SuggestionItem
                refetch={refetch}
                artworkId={artworkId}
                key={suggestion._id}
                suggestion={suggestion}
              />
            ))}
          </div>
        )}

        {!suggestions?.length && <ArtworkDetailsMap artwork={artwork} />}
        {artwork && !!filteredCollectors?.length && !artwork?.collector && (
          <ExploreItem title="Collected by">
            {filteredCollectors.map((item: IArtworkCollector) => (
              <p key={uniqid()}>
                {item?.collectedBy} {item.yearCollected && <span>, {item.yearCollected}</span>}
              </p>
            ))}
          </ExploreItem>
        )}

        {displayConditionReport && (
          <ExploreItem title="Condition report" text={artwork?.conditionReport} />
        )}

        {displayNotes && <ExploreItem title="Notes, Tools & Techniques" text={artwork?.notes} />}

        {!!artwork?.attachments.length && (
          <ExploreItem title="Video">
            <ReactPlayer url={artwork.attachments[0].url} controls width="100%" />
          </ExploreItem>
        )}

        {displayInspiration && (
          <ExploreItem title="Origins of Inspiration" text={artwork?.inspiration} />
        )}

        {artwork && !!filteredPastExhibitions?.length && (
          <ExploreItem title="Past Exhibitions/Appraisal">
            {filteredPastExhibitions.map((item: any) => (
              <p key={uniqid()}>
                {item?.exhibition} {item.year && <span>, {item?.year}</span>}
              </p>
            ))}
          </ExploreItem>
        )}

        <ArtworkTabs tabs={artworkTabs} selectedId={selectedTabId} onClick={handleTabClick} />

        <div className="tabs_page__content">
          {selectedTabId === artworkTabsList[0].id && artwork && (
            <Comments
              handleReply={handleReply}
              artworkId={artworkId || ""}
              ref={myRef}
              refetch={refetch}
              limit={limit}
              comments={comments}
            />
          )}

          {selectedTabId === artworkTabsList[1].id && !!artwork?.transferHistory.length && (
            <TransferHistoryList
              transferHistory={artwork?.transferHistory}
              pastCollectors={artwork?.pastCollectors}
            />
          )}

          {selectedTabId === artworkTabsList[2].id && !!artwork?.exhibitions.length && (
            <ArtworkShows
              showMoreShows={showMoreShows}
              artworkShows={artworkShows}
              loadMoreShows={loadMoreShows}
            />
          )}
        </div>
        {loadMoreButton && selectedTabId === artworkTabsList[0].id && (
          <div className="load_more__button">
            <Button
              onClick={loadMore}
              children="Load more comments"
              variant="outlined"
              className="load_more"
            />
          </div>
        )}
      </div>
      <div ref={commentTypeSelect} className="explore_item_details_right__footer">
        {showTypeComment && (
          <p className="type_comment">
            Your {checkedCommentInput} is{" "}
            <Button
              onClick={activeClassHandler(false)}
              variant="text"
              className={cn("comment_type_btn", {
                active: !typeComment,
              })}
            >
              PUBLIC
            </Button>{" "}
            <Button
              onClick={openModal}
              variant="text"
              className={cn("comment_type_btn", {
                active: typeComment,
              })}
            >
              PRIVATE
            </Button>
          </p>
        )}
        <label htmlFor="comment_input">
          <TextareaAutosize
            className="comment_input"
            name={commentType ? "comment" : "reply"}
            minRows={1}
            maxRows={5}
            value={commentType ? commentValue.comment : commentValue.reply}
            onChange={handleChangeComment}
            placeholder={commentType ? "Leave comment" : "Reply comment"}
            style={{ width: "100%" }}
            ref={inputFocus}
            onFocus={showTypeCommentHandle}
          />
          {(commentValue.comment || commentValue.reply) && (
            <button onMouseDown={handleOnClick} className="comment_input_btn">
              Send
            </button>
          )}
        </label>
      </div>
    </div>
  );
};
export default memo(ExploreItemDetailsRight);
