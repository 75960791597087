import React from "react";

import { NavLink } from "react-router-dom";

import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import { explore } from "../../route-link";

import "./NavMenu.scss";
import Notifications from "../notifications/Notifications";

const NavMenuLogin = (): JSX.Element => {
  return (
    <div className="nav_menu__login">
      <Notifications />
      <NavLink to={explore}>
        <ExploreOutlinedIcon className="nav_menu__login__item" />
      </NavLink>
    </div>
  );
};

export default NavMenuLogin;
