import React from "react";
import { Link } from "react-router-dom";

import { artists, explore, shows } from "../../route-link";
import NotificationTime from "./NotificationTime";

interface PublicCommentsShowNotificationProps {
  notice: any;
  nameFollower: string;
  userId: string;
  textComment: string;
  time: Date;
}

const PublicCommentsShowNotification: React.FC<PublicCommentsShowNotificationProps> = ({
  notice,
  nameFollower,
  userId,
  time,
  textComment,
}): JSX.Element => {
  const titleExhibition = notice?.audited?.exhibition?.title;
  return (
    <div className="notification_grid">
      <p className="notification_text">
        <Link className="notification_link" to={`${explore}/${artists}/${userId}`}>
          {nameFollower}
        </Link>
        left a comment on{" "}
        <Link className="notification_link" to={`${explore}/${shows}/`}>
          {titleExhibition}
        </Link>
        : "{textComment}"
      </p>
      <NotificationTime time={time} />
    </div>
  );
};

export default PublicCommentsShowNotification;
