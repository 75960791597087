/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-nested-ternary */
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import uniqid from "uniqid";
import {
  // Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  AVAILABLE_ARTWORK_COLORS,
  AVAILABLE_ARTWORK_MATERIALS,
} from "../../api-queries/ArtworksRequests";
import debounce from "../../utils/debounce";

import {
  Category,
  ErrorFields,
  getCollectors,
  InitialCategory,
  InputType,
  TextMaterial,
} from "./edit.props";
import { ArtworksRequests } from "../../api-queries/AllRequests";
import { IFilterTypes } from "../../types/filterTypes";
import LocationAutocomplete from "../locations-autocomplete/LocationAutocomplete";
import { ILocationFilter } from "../../context/types/artistsPageTypes";
import EditDimensions from "./components/EditDimensions";
import { MenuProps } from "./edit.style";
import EditDoubleInput from "./components/EditDoubleInput";
import { deleteAdditionalImage, updateArtwork } from "../../api-queries/GraohQlClient";
import { IArtworkDimensions } from "../../types/artwork/artworkDimensions";
import EditImages from "./components/EditImages";
import {
  artworks,
  editUnfinishedArtwork,
  explore,
  uploadArtworkInfo,
  uploadedNow,
} from "../../route-link";
import { getCoordinates } from "../location-filter-select/utils";
import { ICollector } from "../../types/collector";
import { IExhibitions } from "../../types/exhibitions";
import { useActions } from "../../hooks/useActions";
import { ArtworkTypes } from "../../types/artwork/artwork";
import { PREDEFINED_COLORS, PREDEFINED_MATERIALS } from "../../data/constants";
import EditRenderItemsMiddleware from "./components/EditRenderItemsMiddleware";
import MyButton from "../../UI/button/Button";
import { validateLink, validateLength } from "../../utils/validate-link";
import { categoriesDescription } from "../../utils/categories-description";
import { useArtworksSldrCntx } from "../../context/artworksSldrCntx";
import { removeArtworkFromLocalStorage } from "../../utils/removeArtworkFromLocalStorage";

interface EditArtworkArtistProps {
  isUploadArtwork?: boolean;
}

const initialErrorFields: ErrorFields = {
  titleError: "",
  yearError: "",
  originError: "",
  conditionReportError: "",
  widthError: "",
  weightError: "",
  heightError: "",
  thicknessError: "",
  lengthError: "",
  notesError: "",
  locationError: "",
  locationLivesError: "",
  locationFoundError: "",
  materialsError: "",
  colorsError: "",
  categoriesError: "",
  collectedAtError: "",
  videoUrlError: "",
  priceError: "",
  nameOfArtistError: "",
  exhibitionsError: "",
  collectedError: "",
};

const EditArtworkArtist: FC<EditArtworkArtistProps> = ({ isUploadArtwork }) => {
  const artwork = useLocation();
  const navigate = useNavigate();
  const { state }: any = artwork;
  const { setUploadArtwork } = useActions();
  const { setArtworks: setSldrAtrwrs } = useArtworksSldrCntx();
  const routerLocation = useLocation();
  const [mocaExhebitionChecked, setMocaExhebitionRef] = useState(state?.selectedForVote || false);
  const [showErrors, setShowErrors] = useState(!isUploadArtwork);
  const [additionalImagesToDelete, setAdditionalImagesToDelete] = useState<any>([]);
  const typeArtist = state.type === "artist";
  const typeCollector = state.type === "collector";
  const typeFound = state.type === "found";
  const uploadPage = routerLocation.pathname.includes(`/${uploadArtworkInfo}`);
  const editUncompletedPage = routerLocation.pathname.includes(`/${editUnfinishedArtwork}`);
  const initialObject = {
    id: uniqid(),
    firstInputValue: "",
    secondInputValue: "",
  };
  // type found
  const initialCategoriesId = state?.categories?.map((category: InitialCategory) => category._id);
  const { id: _id }: any = useParams();
  const initialMaterials =
    state?.materials?.map((el: string) => {
      return {
        value: el,
        id: uniqid(),
        addNewActive: false,
      };
    }) || [];
  const initialColors =
    state?.colors?.map((el: string) => {
      return {
        value: el,
        id: uniqid(),
        addNewActive: false,
      };
    }) || [];
  const initialCollected = useMemo(() => {
    return (
      (state?.collectors?.length &&
        state?.collectors?.map((el: ICollector) => {
          return {
            id: uniqid(),
            firstInputValue: el.collectedAt?.name
              ? { label: el.collectedAt?.name }
              : el.collectedBy,
            secondInputValue: el.yearCollected,
          };
        })) || [initialObject]
    );
  }, []);
  const initialPastExhiditions = useMemo(() => {
    return (
      state?.pastExhibitions?.map((el: IExhibitions) => {
        return {
          id: uniqid(),
          firstInputValue: el?.exhibition,
          secondInputValue: el?.year,
        };
      }) || [initialObject]
    );
  }, []);
  const [apolloQuery, setApolloQuery] = useState(AVAILABLE_ARTWORK_MATERIALS);
  const [categories, setCategories] = useState([]);
  const [categoriesSelect, setCategoriesSelect] = useState(false);

  const openCategoriesSelect = (): void => {
    setCategoriesSelect(true);
  };
  const closeCategoriesSelect = (): void => {
    setCategoriesSelect(false);
  };
  const validateDoubleInputs = (
    array: [{ id: string; firstInputValue: string; secondInputValue: string }],
    isCollected = false,
  ): boolean => {
    return array.every(el => {
      const fields = Object.values(el).filter(val => val !== el.id);
      const isAllFieldsFilled = fields.every(field => !!field);
      const isSomeFieldsFilled = fields.some(field => !!field);
      if (isCollected && !isAllFieldsFilled && !isSomeFieldsFilled) return false;
      if (!isAllFieldsFilled && isSomeFieldsFilled) return false;
      return true;
    });
  };
  // state inputs
  const [errorFields, setErrorFields] = useState<ErrorFields>(
    isUploadArtwork
      ? initialErrorFields
      : {
          titleError: state.name
            ? ""
            : state.type !== ArtworkTypes.FOUND
            ? "Name of artwork is required"
            : "",
          yearError: state.createdByArtistYear
            ? ""
            : state.type !== ArtworkTypes.FOUND
            ? "Year created is required"
            : "",
          originError:
            !state.inspiration && state.type === ArtworkTypes.ARTIST
              ? "Inspiration is required"
              : "",
          conditionReportError: state.conditionReport
            ? ""
            : state.type !== ArtworkTypes.FOUND
            ? "Condition report is required"
            : "",
          notesError:
            !state.notes && state.type === ArtworkTypes.ARTIST
              ? "Notes, tools & techniques is required"
              : "",
          locationError:
            !state.locationCreated?.name && state.type !== ArtworkTypes.COLLECTOR
              ? "Location created is required"
              : "",
          locationLivesError:
            !state.locationLives?.name && !typeFound ? "Lives at location is required" : "",
          locationFoundError:
            !state.locationFound?.name && typeFound
              ? "Location where artwork was found is required"
              : "",
          materialsError:
            (!state.materials?.length || state.materials?.every((value: any) => !!value.value)) &&
            state.type !== ArtworkTypes.FOUND
              ? "Materials is required"
              : "",
          colorsError:
            !state.colors?.length || state.colors?.every((value: any) => !!value.value)
              ? "Colors is required"
              : "",
          categoriesError:
            state.categories?.length > 0 ? "" : "Artwork should have at least one category",
          collectedAtError: validateDoubleInputs(initialCollected, typeCollector)
            ? ""
            : "Location where artwork is collected is required",
          videoUrlError: "",
          heightError: state.dimensions?.height
            ? ""
            : state.type !== ArtworkTypes.FOUND
            ? "Height is required"
            : "",
          thicknessError: state.dimensions?.length
            ? ""
            : state.type !== ArtworkTypes.FOUND
            ? "Thickness is required"
            : "",
          lengthError: state.dimensions?.length
            ? ""
            : state.type !== ArtworkTypes.FOUND
            ? "Thickness is required"
            : "",
          widthError: state.dimensions?.width
            ? ""
            : state.type !== ArtworkTypes.FOUND
            ? "Width is required"
            : "",
          weightError: state.dimensions?.weight
            ? ""
            : state.type !== ArtworkTypes.FOUND
            ? "Weight is required"
            : "",
          priceError: "",
          nameOfArtistError: state.artistName ? "" : "Artist name is required",
          exhibitionsError: validateDoubleInputs(initialPastExhiditions)
            ? ""
            : "Both fields must be filled or empty",
          collectedError: validateDoubleInputs(initialCollected)
            ? ""
            : "Both fields must be filled or empty",
        },
  );
  const initPrice = state?.minimumBid?.toLocaleString("en-US").split(",").join("") || "";
  const [price, setPrice] = useState(initPrice);
  const [nameOfArtist, setNameOfArtist] = useState(state.artistName || "");
  const [nameOfArtwork, setNameOfArtwork] = useState(state.name || "");
  const [title, setTitle] = useState(state.name || "");
  const [year, setYear] = useState(state.createdByArtistYear || "");
  const [origin, setOrigin] = useState(state.inspiration || "");
  const [conditionReport, setConditionReport] = useState(state.conditionReport || "");
  const [dimensions, setDimensions] = useState<IArtworkDimensions>();
  const [additionalImages, setAdditionalImages] = useState(state?.additionalImages || []);
  const [notes, setNotes] = useState(state.notes || "");
  const initVideoUrl = state?.attachments?.length ? state.attachments[0]?.url : "";
  const [videoUrl, setVideoUrl] = useState(initVideoUrl);
  const initLocation = state?.locationCreated?.name
    ? {
        label: state?.locationCreated?.name,
        value: null,
      }
    : null;
  const initLocationLives = state?.locationLives?.name
    ? {
        label: state?.locationLives?.name,
        value: null,
      }
    : null;
  const initlocationFound = state?.locationFound?.name
    ? {
        label: state?.locationFound?.name,
        value: null,
      }
    : null;
  const [location, setLocation] = useState<ILocationFilter | null>(initLocation);
  const [locationLives, setLocationLives] = useState<ILocationFilter | null>(initLocationLives);
  const [locationFound, setLocationFound] = useState<any>(initlocationFound);
  const [materials, setMaterials] = useState<Array<InputType>>([
    ...initialMaterials,
    {
      value: "",
      id: uniqid(),
      addNewActive: true,
    },
  ]);
  const [colors, setColors] = useState<Array<InputType>>([
    ...initialColors,
    {
      value: "",
      id: uniqid(),
      addNewActive: true,
    },
  ]);
  const [collected, setCollected] = useState<any>(initialCollected);
  const [pastExhibitions, setPastExhibitions] = useState(initialPastExhiditions);
  // state checked
  const [checkFilmsOrTv, setCheckFilmsOrTv] = useState(state?.allowUseForFilmAndTv || false);
  const [checkArtworkForSale, setCheckArtworkForSale] = useState(false);
  const [unknown, setUnknown] = useState({
    artistName: false,
    year: false,
    nameOfArtwork: false,
  });
  const [unknownMaterials, setUnknownMaterials] = useState(false);

  // state toggle
  const [toggle, setToggle] = useState(false);
  const [toggleColor, setToggleColor] = useState(false);

  // state for text
  const [textMaterial, setTextMaterial] = useState<TextMaterial>({
    text: "",
    id: "",
  });
  const [textColor, setTextColor] = useState<TextMaterial>({
    text: "",
    id: "",
  });

  const [loadItems, { data }] = useLazyQuery(apolloQuery, {
    variables: { text: textMaterial.text },
  });
  const [loadCategoties] = useLazyQuery(ArtworksRequests.CATEGORIES);

  const getCategories = async (): Promise<void> => {
    const res = await loadCategoties();
    const defaultCategories = res.data.categories.map((category: any) => {
      return {
        id: category._id,
        type: IFilterTypes.Categories,
        checked: false,
        value: category.name,
      };
    });
    setCategories(defaultCategories);
  };
  // click functions
  const onItemClick = (text: string, id: number | string): void => {
    const index = materials.findIndex(el => el.id === id);
    materials[index].value = text.toString();
    setMaterials([...materials]);
    setToggle(false);
    setErrorFields({ ...errorFields, materialsError: "" });
  };

  const onItemClickColor = (text: string, id: number | string): void => {
    const index = colors.findIndex(el => el.id === id);
    colors[index].value = text.toString();
    setColors([...colors]);
    setToggleColor(false);
    setErrorFields({ ...errorFields, colorsError: "" });
  };

  // handle change functions
  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(event.target.value);
    if (!event.target.value.length && state.type !== ArtworkTypes.FOUND) {
      setErrorFields({ ...errorFields, titleError: "Name of artwork is required" });
      return;
    }
    setErrorFields({ ...errorFields, titleError: "" });
  };
  const handleChangeNameOfArtist = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNameOfArtist(event.target.value);
    if (!event.target.value.length) {
      setErrorFields({ ...errorFields, nameOfArtistError: "Artist name is required" });
      return;
    }
    setErrorFields({ ...errorFields, nameOfArtistError: "" });
  };
  const handleChangeNameOfArtwork = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNameOfArtwork(event.target.value);
  };
  const handleChangeYear = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.value.length && state.type !== ArtworkTypes.FOUND) {
      setYear(event.target.value);
      setErrorFields({ ...errorFields, yearError: "Year created is required" });
      return;
    }
    const re = /^[0-9\b]+$/;
    if (re.test(event.target.value)) {
      setYear(event.target.value);
      setErrorFields({ ...errorFields, yearError: "" });
    }
  };
  const handleChangeOrigin = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setOrigin(event.target.value);
    if (!event.target.value.length && state.type === ArtworkTypes.ARTIST) {
      setErrorFields({ ...errorFields, originError: "Inspiration is required" });
      return;
    }
    setErrorFields({ ...errorFields, originError: "" });
  };
  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPrice(event.target.value);
    if (!event.target.value.length) {
      setErrorFields({ ...errorFields, priceError: "Price is required" });
      return;
    }
    setErrorFields({ ...errorFields, priceError: "" });
  };
  const handleChangeConditionReport = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConditionReport(event.target.value);
    if (!event.target.value.length && (typeCollector || typeArtist)) {
      setErrorFields({ ...errorFields, conditionReportError: "Condition report is required" });
      return;
    }
    setErrorFields({ ...errorFields, conditionReportError: "" });
  };
  const handleChangeNotes = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNotes(event.target.value);
    if (!event.target.value.length && typeArtist) {
      setErrorFields({ ...errorFields, notesError: "Notes, tools & techniques is required" });
      return;
    }
    setErrorFields({ ...errorFields, notesError: "" });
  };
  const handleChangeVideoUrl = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    if (!value) setVideoUrl("");

    setErrorFields({ ...errorFields, videoUrlError: "" });
    if (validateLength(value)) {
      if (!validateLink(value)) {
        setErrorFields({
          ...errorFields,
          videoUrlError: "Should be a secure URL (starts with https://)",
        });
      }
    }
    setVideoUrl(value);
  };
  const handleChangeLocation = (value: ILocationFilter): void => {
    setLocation(value);
    if (!value.label && state.type !== "collector") {
      setErrorFields({ ...errorFields, locationError: "Location created is required" });
      return;
    }
    setErrorFields({ ...errorFields, locationError: "" });
  };
  const handleChangeLocationLives = (value: ILocationFilter): void => {
    setLocationLives(value);
    if (!value.label) {
      setErrorFields({ ...errorFields, locationLivesError: "Lives at location is required" });
      return;
    }
    setErrorFields({ ...errorFields, locationLivesError: "" });
  };
  const handleChangeLocationFound = (value: ILocationFilter): void => {
    setLocationFound(value);
    if (!value.label) {
      setErrorFields({
        ...errorFields,
        locationFoundError: "Location where artwork was found is required",
      });
      return;
    }
    setErrorFields({ ...errorFields, locationFoundError: "" });
  };

  const handleChangeMaterial = (
    id: number | string,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setErrorFields({ ...errorFields, materialsError: "" });
    setApolloQuery(AVAILABLE_ARTWORK_MATERIALS);
    const values = [...materials];
    const index = values.findIndex(el => el.id === id);
    values[index].value = event.target.value;
    if (
      !values.length ||
      (values.every(value => !value.value) &&
        !unknownMaterials &&
        state.type !== ArtworkTypes.FOUND)
    ) {
      setErrorFields({ ...errorFields, materialsError: "Materials is required" });
    }
    setTextMaterial({ ...textMaterial, text: values[index]?.value, id });
    setMaterials(values);
    setToggle(true);
    loadItems({
      variables: {
        text: values[index].value,
      },
    });
  };

  const handleChangeColor = (
    id: number | string,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setErrorFields({ ...errorFields, colorsError: "" });
    setApolloQuery(AVAILABLE_ARTWORK_COLORS);
    const values = [...colors];
    const index = values.findIndex(el => el.id === id);
    values[index].value = event.target.value;
    if (!values.length || values.every(value => !value.value)) {
      setErrorFields({ ...errorFields, colorsError: "Colors is required" });
    }
    setTextColor({ ...textColor, text: values[index].value, id });
    setColors(values);
    setToggleColor(true);
    loadItems({
      variables: {
        text: values[index].value,
      },
    });
  };

  const handleChangeFilmsOrTv = (): void => setCheckFilmsOrTv((prev: boolean | undefined) => !prev);

  const checkedArtworkForSale = (): void => {
    setCheckArtworkForSale(prev => !prev);
    setPrice("");
  };
  const handleMocaExhebitionChecked = (): void => setMocaExhebitionRef((prev: boolean) => !prev);

  const onUnknownChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: (param: string) => void,
  ): void => {
    if (e.target.checked) setter("");
    const newUnknown = { ...unknown, [e.target.value]: e.target.checked };
    setUnknown(newUnknown);
  };
  const onUnknownMaterialsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      setMaterials([
        {
          value: "",
          id: uniqid(),
          addNewActive: true,
        },
      ]);
    }
    setUnknownMaterials(e.target.checked);
    setErrorFields({ ...errorFields, materialsError: "" });
  };
  const onUnknownYearChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) setYear("");

    setUnknown({ ...unknown, year: e.target.checked });
    setErrorFields({ ...errorFields, yearError: "" });
  };

  // handle add and remove functions
  const handleAddMaterial = (
    array: Array<any>,
    event: React.SyntheticEvent,
    setArray: (array: Array<InputType>) => any,
    id: number | string,
  ): void => {
    event.preventDefault();
    const values = [...array];
    const index = values.findIndex(el => el.id === id);
    values[index].addNewActive = false;
    setArray([...array, { value: "", id: Date.now(), addNewActive: true }]);
  };

  const handleRemoveField = (
    id: number | string,
    array: Array<any>,
    setFunc: (value: any) => void,
    type: string,
  ): void => {
    const filter: Array<InputType> = array.filter(el => el.id !== id);
    setFunc(filter);
    if (!filter.length || filter.every(value => !value.value)) {
      switch (type) {
        case "material":
          if (!typeFound)
            setErrorFields({ ...errorFields, materialsError: "Materials is required" });
          break;
        case "color":
          setErrorFields({ ...errorFields, colorsError: "Colors is required" });
          break;
        default:
      }
    }
  };

  const onCloseIconClickLocation = (): void => {
    setLocation(null);
    if (state?.type !== "collector") {
      setErrorFields({ ...errorFields, locationError: "Location created is required" });
    }
  };
  const onCloseIconClickLocationLives = (): void => {
    setLocationLives(null);
    setErrorFields({ ...errorFields, locationLivesError: "Lives at location is required" });
  };
  const onCloseIconClickLocationFound = (): void => {
    setLocationFound(null);
    setErrorFields({
      ...errorFields,
      locationFoundError: "Location where artwork was found is required",
    });
  };

  // render buttons
  const buttons = (
    el: InputType,
    array: Array<InputType>,
    setArray: any,
    type: "material" | "color",
  ): JSX.Element => {
    const setFunc = type === "material" ? setMaterials : setColors;
    return el.addNewActive ? (
      <AddIcon
        className="add_input__multi"
        onClick={e => handleAddMaterial(array, e, setArray, el.id)}
      />
    ) : (
      <CloseIcon
        onClick={() => handleRemoveField(el.id, array, setFunc, type)}
        className="close_delete"
      />
    );
  };

  const [personCategoriesId, setPersonCategoriesId] = React.useState<string[]>(
    initialCategoriesId || [],
  );
  const handleChange = (event: SelectChangeEvent<typeof personCategoriesId>): void => {
    const {
      target: { value },
    } = event;
    if (value.length > 3) {
      closeCategoriesSelect();
      setErrorFields({
        ...errorFields,
        categoriesError: "Cannot select more than 3 categories",
      });
      return;
    }

    setPersonCategoriesId(typeof value === "string" ? value.split(",") : value);
    if (value.length === 0) {
      closeCategoriesSelect();
      setErrorFields({
        ...errorFields,
        categoriesError: "Artwork should have at least one category",
      });
      return;
    }

    setErrorFields({ ...errorFields, categoriesError: "" });
  };

  const pasteLink = async (): Promise<void> => {
    setVideoUrl(await navigator.clipboard.readText());
  };

  useEffect(() => {
    window.scroll(0, 0);
    if (state?.saleable) setCheckArtworkForSale(true);
    getCategories();
  }, []);

  const renderValue = (selected: Array<string>): string =>
    categories
      .filter((value: any) => selected.includes(value.id))
      .map((c: any) => c.value)
      .join(", ");

  const reduceValue = (array: Array<InputType>): Array<string> => {
    return array.reduce((acc: any, m) => {
      if (m.value) acc.push(m.value);
      return acc;
    }, []);
  };

  const getDimensionsValue = (dimensionsValue: IArtworkDimensions): void => {
    setDimensions(dimensionsValue);
  };
  const removeEmptyFields = <T,>(array: T[]): T[] => {
    return array.filter(el => {
      const allFields: string[] = Object.values(el);
      return allFields.every(field => !!field);
    });
  };
  const removeEmptyCollectors = (collectors: ICollector[]): ICollector[] => {
    return collectors.filter(collector => Object.values(collector).some(el => el));
  };
  const handleUpdateArtwork = async (finished = true): Promise<void> => {
    const args: any = {
      _id: state.id || _id,
      name: typeFound ? nameOfArtwork : title,
      yearCreated: year,
      price: +price,
      materials: reduceValue(materials),
      categories: personCategoriesId,
      colors: reduceValue(colors),
      inspiration: origin,
      artistName: nameOfArtist,
      dimensions,
      notes,
      conditionReport,
      type: state.type,
      saleable: checkArtworkForSale,
      allowUseForFilmAndTv: checkFilmsOrTv,
      hasFilmsOrTv: checkFilmsOrTv,
      selectedForVote: checkArtworkForSale && mocaExhebitionChecked,
      additionalImages: additionalImages.map((image: any) => {
        delete image.__typename;
        delete image.medium.__typename;
        delete image.origin.__typename;
        delete image.thumbnail.__typename;
        return image;
      }),
    };
    // set locations
    if (!locationLives?.label) {
      args.locationLives = null;
    } else if (typeArtist || typeCollector) {
      const isInitLoc = state?.locationLives?.name === locationLives.label;
      const coordinates = isInitLoc
        ? state.locationLives.coordinates
        : await getCoordinates(locationLives);
      delete state?.locationLives?.coordinates?.__typename;
      args.locationLives = { name: locationLives.label, coordinates };
    }

    if (!location?.label) {
      args.locationCreated = null;
    } else if (typeArtist || typeCollector) {
      const isInitLoc = state?.locationCreated?.name === location.label;
      const coordinates = isInitLoc
        ? state.locationCreated.coordinates
        : await getCoordinates(location);
      delete state?.locationCreated?.coordinates?.__typename;
      args.locationCreated = { name: location.label, coordinates };
    }

    if (!locationFound?.label) {
      args.locationFound = null;
    } else if (typeFound) {
      const isInitLoc = state.locationFound?.name === locationFound.label;
      const coordinates = isInitLoc
        ? state?.locationFound?.coordinates
        : await getCoordinates(locationFound);
      delete state?.locationFound?.coordinates?.__typename;
      args.locationFound = { name: locationFound.label, coordinates };
    }

    // set video u  rl
    if (videoUrl) {
      args.attachments = [{ url: videoUrl, type: "video" }];
    } else {
      args.attachments = [];
    }

    // delete additional images
    const removeAdditionalImagesPromises = additionalImagesToDelete.map(async (imageId: any) => {
      return deleteAdditionalImage({ artworkId: _id, imageId });
    });

    await Promise.all(removeAdditionalImagesPromises);
    // set collectors
    const collectors = await Promise.all(
      (await getCollectors(collected, state.type, state.collectors)) || [],
    );
    const filteredCollectors = removeEmptyCollectors(collectors);
    args.collectors = filteredCollectors;
    if (filteredCollectors.length) {
      args.locationCollected = filteredCollectors[0].collectedAt;
    } else {
      args.locationCollected = null;
    }
    // set pastExhibitions
    if (pastExhibitions?.length) {
      const pastExhibitionsQueryData = pastExhibitions.map((el: any) => ({
        exhibition: el.firstInputValue,
        year: el.secondInputValue,
      }));
      const filteredPastExhibitions = removeEmptyFields(pastExhibitionsQueryData);
      args.pastExhibitions = filteredPastExhibitions;
    }

    try {
      const updatedArtwork = await updateArtwork({
        creationInProgress: false,
        published: finished,
        ...args,
      });
      if (uploadPage || editUncompletedPage) {
        if (finished) {
          removeArtworkFromLocalStorage(updatedArtwork.data?.updateArtwork?._id);
          setSldrAtrwrs([updatedArtwork.data?.updateArtwork]);
          navigate(
            `/${explore}/${artworks}/${updatedArtwork.data?.updateArtwork?._id}/${uploadedNow}`,
            { state: { backSteps: uploadPage ? -3 : -2 } },
          );
          return;
        }
        navigate(uploadPage ? -2 : -1);
        return;
      }
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (initialCollected && initialPastExhiditions) {
      const validateExhibitions = validateDoubleInputs(initialPastExhiditions);
      const validateCollected = validateDoubleInputs(initialCollected);

      if (!validateExhibitions) {
        setErrorFields({
          ...errorFields,
          exhibitionsError: "Both fields must be filled or empty",
        });
      }
      if (!validateCollected) {
        setErrorFields({
          ...errorFields,
          collectedError: "Both fields must be filled or empty",
        });
      }
    }
  }, [initialPastExhiditions, initialCollected]);
  // eslint-disable-next-line consistent-return
  const handleSubmit = async (event: React.SyntheticEvent): Promise<void> => {
    event.preventDefault();
    const validateExhibitions = validateDoubleInputs(pastExhibitions);
    const validateCollected = validateDoubleInputs(collected);
    if (
      (materials.every((value: any) => !value.value) &&
        !unknownMaterials &&
        state.type !== "found") ||
      colors.every((value: any) => !value.value) ||
      (!title && state.type !== "found") ||
      (!year && typeCollector) ||
      (!year && typeArtist) ||
      (!locationLives && state.type === "collector") ||
      !personCategoriesId.length ||
      (!location?.label && typeArtist) ||
      // (!location?.label && typeCollector) ||
      (!locationFound?.label && state.type === "found") ||
      (!locationFound && state.type === "found") ||
      (!notes && typeArtist) ||
      (!origin && state.type === "artist") ||
      (!collected[0]?.firstInputValue?.label && state.type === "collector") ||
      (videoUrl && !videoUrl.startsWith("https://")) ||
      (!conditionReport && state.type !== "found") ||
      (!dimensions?.width && state.type !== "found") ||
      (!dimensions?.height && state.type !== "found") ||
      (!dimensions?.weight && state.type !== "found") ||
      (!dimensions?.length && state.type !== "found") ||
      (checkArtworkForSale && !price) ||
      (!locationLives?.label && state.type === "artist") ||
      (!locationLives?.label && state.type === "collector") ||
      (!nameOfArtist && state.type === "collector") ||
      (pastExhibitions.length && !validateExhibitions) ||
      (collected.length && !validateCollected)
    ) {
      const resultError: ErrorFields = {
        titleError: "",
        yearError: "",
        originError: "",
        conditionReportError: "",
        widthError: "",
        weightError: "",
        heightError: "",
        thicknessError: "",
        lengthError: "",
        notesError: "",
        locationError: "",
        locationLivesError: "",
        locationFoundError: "",
        materialsError: "",
        colorsError: "",
        categoriesError: "",
        collectedAtError: "",
        videoUrlError: "",
        priceError: "",
        nameOfArtistError: "",
        exhibitionsError: "",
        collectedError: "",
      };
      if (
        (!conditionReport && state.type === ArtworkTypes.ARTIST) ||
        (!conditionReport && state.type === ArtworkTypes.COLLECTOR)
      ) {
        resultError.conditionReportError = "Condition report is required";
      }
      if (!title && state.type !== ArtworkTypes.FOUND) {
        resultError.titleError = "Name of artwork is required";
      }
      if ((!year && typeArtist) || (!year && typeCollector)) {
        resultError.yearError = "Year created is required";
      }
      if (
        materials.every((value: any) => !value.value) &&
        !unknownMaterials &&
        state.type !== ArtworkTypes.FOUND
      ) {
        resultError.materialsError = "Materials is required";
      }
      if (colors.every((value: any) => !value.value)) {
        resultError.colorsError = "Colors is required";
      }
      if (!personCategoriesId.length) {
        resultError.categoriesError = "Artwork should have at least one category";
      }
      if (!location?.label && state.type !== "collector") {
        resultError.locationError = "Location created is required";
      }
      if (!locationLives?.label) {
        resultError.locationLivesError = "Lives at location is required";
      }
      if (!locationFound?.label) {
        resultError.locationFoundError = "Location where artwork was found is required";
      }
      if (!notes && typeArtist) {
        resultError.notesError = "Notes, tools & techniques is required";
      }
      if ((!dimensions?.width || dimensions?.width === 0) && state.type !== "found") {
        resultError.widthError = "Width is required";
      }
      if ((!dimensions?.weight || dimensions?.weight === 0) && state.type !== "found") {
        resultError.weightError = "Weight is required";
      }
      if ((!dimensions?.length || dimensions?.length === 0) && state.type !== "found") {
        resultError.lengthError = "Thickness is required";
      }
      if ((!dimensions?.height || dimensions?.height === 0) && state.type !== "found") {
        resultError.heightError = "Height is required";
      }
      if (!price) {
        resultError.priceError = "Price is required";
      }
      if (!nameOfArtist) {
        resultError.nameOfArtistError = "Artist name is required";
      }
      if (!origin && state.type === ArtworkTypes.ARTIST) {
        resultError.originError = "Inspiration is required";
      }
      if (!collected[0]?.firstInputValue?.label) {
        resultError.collectedAtError = "Location where artwork is collected is required";
      }
      if (videoUrl && !videoUrl.startsWith("https://")) {
        resultError.videoUrlError = "Should be a secure url (starts with https://)";
      }
      if (pastExhibitions.length && !validateExhibitions) {
        resultError.exhibitionsError = "Both fields must be filled or empty";
      }
      if (collected.length && !validateCollected) {
        resultError.collectedError = "Both fields must be filled or empty";
      }
      setErrorFields({ ...resultError });
      return;
    }
    handleUpdateArtwork();
  };

  const onSubmit = (event: React.SyntheticEvent): void => {
    setShowErrors(true);
    handleSubmit(event);
    setUploadArtwork("");
  };

  const focusMaterials = (id: string | number) => () => {
    setToggle(true);
    setApolloQuery(AVAILABLE_ARTWORK_MATERIALS);
    const newValue = materials.find(color => color.id === id)?.value;
    if (newValue) {
      loadItems({
        variables: {
          text: newValue,
        },
      });
    }
    setTextMaterial({ ...textMaterial, id });
    setToggleColor(false);
  };

  const focusColors = (id: string | number) => () => {
    setToggleColor(true);
    setApolloQuery(AVAILABLE_ARTWORK_COLORS);
    const newValue = colors.find(color => color.id === id)?.value;
    if (newValue) {
      loadItems({
        variables: {
          text: newValue,
        },
      });
    }

    setTextColor({ ...textColor, id });
    setToggle(false);
  };
  const Provenance = (): JSX.Element => (
    <p className="edit_text">Add Provenance/History/More info</p>
  );

  const collectorOrArtist = state.type === "artist" || state.type === "collector";

  const LocationCreated = (): JSX.Element => (
    <div className="register_item">
      <InputLabel className="origin_label" variant="standard" htmlFor="title">
        Location Created
      </InputLabel>
      <LocationAutocomplete
        value={location}
        className="edit_item__location"
        onChange={handleChangeLocation}
        onCloseIconClick={onCloseIconClickLocation}
        placeholder="Choose location..."
      />
      {isUploadArtwork && typeArtist && (!errorFields.locationError || !showErrors) && (
        <p className="info_message">Location created is required</p>
      )}
      {showErrors && errorFields.locationError && (
        <p className="error_message">{errorFields.locationError}</p>
      )}
    </div>
  );
  const onCloseToggle = (): void => {
    if (toggle) setToggle(false);
    if (toggleColor) setToggleColor(false);
  };
  const onStopPropagation = (e: any): void => e.stopPropagation();
  const titleWindow = isUploadArtwork ? "image" : "artwork";
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form className="edit_form" onSubmit={onSubmit} onClick={onCloseToggle}>
      <div className="edit_form__wrapper">
        <EditImages
          isEditPage
          images={additionalImages}
          setImages={setAdditionalImages}
          additionalImages={state?.additionalImages}
          setAdditionalImagesToDelete={setAdditionalImagesToDelete}
          titleWindow={titleWindow}
        />
        {state.type === "found" && (
          <div className="register_item">
            <InputLabel className="origin_label" variant="standard" htmlFor="name-of-artwork">
              Map it
            </InputLabel>
            <LocationAutocomplete
              value={locationFound}
              className="edit_item__location"
              onChange={handleChangeLocationFound}
              onCloseIconClick={onCloseIconClickLocationFound}
              placeholder="Choose location..."
            />
            {isUploadArtwork && (!errorFields.locationFoundError || !showErrors) && (
              <p className="info_message">Location where artwork was found is required</p>
            )}
            {showErrors && errorFields.locationFoundError && (
              <p className="error_message">{errorFields.locationFoundError}</p>
            )}
          </div>
        )}
        {typeFound && (
          <div className="register_item">
            <InputLabel className="origin_label" variant="standard" htmlFor="name-of-artist">
              Artist name
            </InputLabel>
            <TextField
              id="name-of-artist"
              className="input"
              type="text"
              name="nameOfArtist"
              variant="standard"
              value={nameOfArtist}
              onChange={handleChangeNameOfArtist}
              disabled={unknown.artistName}
            />
            <FormControlLabel
              className="register_item__unknown"
              control={
                <Checkbox
                  value="artistName"
                  checked={unknown.artistName}
                  onChange={e => onUnknownChange(e, setNameOfArtist)}
                />
              }
              label="Unknown"
            />
          </div>
        )}
        {state.type === "collector" && (
          <div className="register_item">
            <InputLabel className="origin_label" variant="standard" htmlFor="name-of-artist">
              Name of Artist
            </InputLabel>
            <TextField
              id="name-of-artist"
              className="input"
              type="text"
              name="nameOfArtist"
              variant="standard"
              value={nameOfArtist}
              onChange={handleChangeNameOfArtist}
            />
            {isUploadArtwork && (!errorFields.nameOfArtistError || !showErrors) && (
              <p className="info_message">Artist name is required</p>
            )}
            {showErrors && errorFields.nameOfArtistError && (
              <p className="error_message">{errorFields.nameOfArtistError}</p>
            )}
          </div>
        )}
        {state.type !== "found" && (
          <div className="register_item">
            <InputLabel className="origin_label" variant="standard" htmlFor="title">
              Title
            </InputLabel>
            <TextField
              id="title"
              className="input"
              type="text"
              name="name"
              variant="standard"
              value={title}
              onChange={handleChangeTitle}
            />
            {isUploadArtwork && (!errorFields.titleError || !showErrors) && (
              <p className="info_message">Name of artwork is required</p>
            )}
            {showErrors && errorFields.titleError && (
              <p className="error_message">{errorFields.titleError}</p>
            )}
          </div>
        )}

        <div className="register_item">
          <InputLabel className="origin_label" variant="standard" htmlFor="year_created">
            Year created
          </InputLabel>
          <TextField
            id="year_created"
            className="input"
            type="text"
            name="name"
            variant="standard"
            value={year}
            onChange={handleChangeYear}
            disabled={unknown.year}
          />
          {state.type === "found" && (
            <FormControlLabel
              className="register_item__unknown"
              control={
                <Checkbox value="year" checked={unknown.year} onChange={onUnknownYearChange} />
              }
              label="Unknown"
            />
          )}
          {isUploadArtwork && !typeFound && (!errorFields.yearError || !showErrors) && (
            <p className="info_message">Year created is required</p>
          )}
          {showErrors && errorFields.yearError && (
            <p className="error_message">{errorFields.yearError}</p>
          )}
        </div>

        <div className="register_item" onClick={onStopPropagation}>
          <InputLabel className="origin_label" variant="standard" htmlFor="material">
            Materials
          </InputLabel>
          {materials.map(material => {
            return (
              <div className="origin_item__input" key={material.id}>
                <TextField
                  onClick={focusMaterials(material.id)}
                  id="year_created"
                  className="input"
                  type="text"
                  name="materials"
                  variant="standard"
                  value={material.value}
                  onChange={e => debounce(handleChangeMaterial(material.id, e), 1000)}
                  disabled={unknownMaterials}
                  autoComplete="off"
                />
                {buttons(material, materials, setMaterials, "material")}
                {toggle && textMaterial.id === material.id && (
                  <EditRenderItemsMiddleware
                    items={data?.availableArtworksMaterials}
                    onItemClick={onItemClick}
                    id={material.id}
                    value={material.value}
                    predefined={PREDEFINED_MATERIALS}
                    arrayCheckItems={materials}
                  />
                )}
              </div>
            );
          })}

          {state.type === "found" && (
            <FormControlLabel
              className="register_item__unknown"
              control={<Checkbox checked={unknownMaterials} onChange={onUnknownMaterialsChange} />}
              label="Unknown"
            />
          )}
          {isUploadArtwork && !typeFound && (!errorFields.materialsError || !showErrors) && (
            <p className="info_message">Materials is required</p>
          )}
          {showErrors && !!errorFields.materialsError && (
            <p className="error_message">{errorFields.materialsError}</p>
          )}
        </div>

        <div className="register_item" onClick={onStopPropagation}>
          <InputLabel className="origin_label" variant="standard" htmlFor="material">
            Colors
          </InputLabel>
          {colors.map(color => {
            return (
              <div className="origin_item__input" key={color.id}>
                <TextField
                  onClick={focusColors(color.id)}
                  id="year_created"
                  className="input"
                  type="text"
                  name="name"
                  variant="standard"
                  value={color.value}
                  onChange={e => debounce(handleChangeColor(color.id, e), 1000)}
                  autoComplete="off"
                />
                {buttons(color, colors, setColors, "color")}
                {toggleColor && textColor.id === color.id && (
                  <EditRenderItemsMiddleware
                    items={data?.availableArtworksColors}
                    onItemClick={onItemClickColor}
                    id={color.id}
                    value={color.value}
                    predefined={PREDEFINED_COLORS}
                    arrayCheckItems={colors}
                  />
                )}
              </div>
            );
          })}
          {isUploadArtwork && (!errorFields.colorsError || !showErrors) && (
            <p className="info_message">Colors is required</p>
          )}
          {showErrors && !!errorFields.colorsError && (
            <p className="error_message">{errorFields.colorsError}</p>
          )}
        </div>

        <div className="register_item">
          <InputLabel className="origin_label" variant="standard" htmlFor="origin">
            Origins Of Inspiration
          </InputLabel>
          <TextField
            id="origin"
            className="input"
            type="text"
            name="name"
            variant="standard"
            value={origin}
            onChange={handleChangeOrigin}
          />
          {isUploadArtwork && typeArtist && (!errorFields.originError || !showErrors) && (
            <p className="info_message">Inspiration is required</p>
          )}
          {showErrors && errorFields.originError && (
            <p className="error_message">{errorFields.originError}</p>
          )}
        </div>

        {state.type === "found" && (
          <div className="register_item">
            <InputLabel className="origin_label" variant="standard" htmlFor="name-of-artwork">
              Name of artwork
            </InputLabel>
            <TextField
              id="name-of-artwork"
              className="input"
              type="text"
              name="name"
              variant="standard"
              value={nameOfArtwork}
              onChange={handleChangeNameOfArtwork}
              disabled={unknown.nameOfArtwork}
            />
            <FormControlLabel
              className="register_item__unknown"
              control={
                <Checkbox
                  checked={unknown.nameOfArtwork}
                  onChange={e => onUnknownChange(e, setNameOfArtwork)}
                  value="nameOfArtwork"
                />
              }
              label="Unknown"
            />
          </div>
        )}

        <div className="register_item">
          <InputLabel className="origin_label" variant="standard" htmlFor="multiple-checkbox">
            Categories
          </InputLabel>
          <div className="register_select__wrap">
            <Select
              className="register_item__select"
              id="multiple-checkbox"
              multiple
              open={categoriesSelect}
              onOpen={openCategoriesSelect}
              onClose={closeCategoriesSelect}
              value={personCategoriesId}
              onChange={handleChange}
              renderValue={renderValue}
              MenuProps={MenuProps}
            >
              {categories.map((category: Category) => (
                <MenuItem key={category.id} value={category.id}>
                  <Checkbox checked={personCategoriesId.indexOf(category.id) > -1} />
                  <div>
                    <ListItemText primary={category.value} />
                    {categoriesDescription(category.value) && (
                      <p className="categories_description">
                        {categoriesDescription(category.value)}
                      </p>
                    )}
                  </div>
                </MenuItem>
              ))}
            </Select>
            {!personCategoriesId.length && (
              <span className="choose_category__span">Choose a category...</span>
            )}
          </div>
          {isUploadArtwork && (!errorFields.categoriesError || !showErrors) && (
            <p className="info_message">Artwork should have at least one category</p>
          )}
          {showErrors && !!errorFields.categoriesError && (
            <p className="error_message">{errorFields.categoriesError}</p>
          )}
        </div>

        {state.type === "artist" && <LocationCreated />}

        {collectorOrArtist && (
          <div className="register_item">
            <InputLabel className="origin_label" variant="standard" htmlFor="title">
              Lives At
            </InputLabel>
            <LocationAutocomplete
              value={locationLives}
              className="edit_item__location"
              onChange={handleChangeLocationLives}
              onCloseIconClick={onCloseIconClickLocationLives}
              placeholder="Choose location..."
            />
            {isUploadArtwork && (!errorFields.locationLivesError || !showErrors) && (
              <p className="info_message">Lives at location is required</p>
            )}
            {showErrors && errorFields.locationLivesError && (
              <p className="error_message">{errorFields.locationLivesError}</p>
            )}
          </div>
        )}

        <EditDimensions
          type={state.type}
          dimensions={state.dimensions || []}
          getDimensionsValue={getDimensionsValue}
          dimensionsError={errorFields}
          setDimensionsError={setErrorFields}
          showErrors={showErrors}
          isUploadArtwork={isUploadArtwork}
        />

        <FormGroup className="register_item">
          {state.type === "artist" && (
            <FormControlLabel
              control={<Checkbox checked={checkFilmsOrTv} onChange={handleChangeFilmsOrTv} />}
              label="Use for films or tv"
            />
          )}
          {checkArtworkForSale && state.type === "artist" && (
            <FormControlLabel
              checked={mocaExhebitionChecked}
              onChange={handleMocaExhebitionChecked}
              control={<Checkbox />}
              label="Submit to NY Moca Exhibition"
            />
          )}
          {(state.type === "artist" || state.type === "collector") && (
            <FormControlLabel
              control={<Checkbox checked={checkArtworkForSale} onChange={checkedArtworkForSale} />}
              label="This artwork for sale"
            />
          )}
          {checkArtworkForSale && (
            <div className="register_item">
              <InputLabel className="origin_label" variant="standard" htmlFor="price">
                Price
              </InputLabel>
              <TextField
                id="price"
                className="input"
                type="number"
                name="name"
                variant="standard"
                value={price}
                onChange={handleChangePrice}
              />
              {isUploadArtwork && typeArtist && (!errorFields.priceError || !showErrors) && (
                <p className="info_message">Price is required</p>
              )}
              {showErrors && errorFields.priceError && (
                <p className="error_message">{errorFields.priceError}</p>
              )}
            </div>
          )}
          {checkArtworkForSale && state.type === "artist" && (
            <FormControlLabel
              checked={mocaExhebitionChecked}
              onChange={handleMocaExhebitionChecked}
              control={<Checkbox />}
              label="Votes get art into The New York Museum of Contemporary Art"
            />
          )}
        </FormGroup>
        {(state.type === "artist" || state.type === "found") && <Provenance />}
        <div className="edit_collected">
          <div className="register_item">
            <EditDoubleInput
              values={collected}
              setValues={setCollected}
              type="collected"
              labelFirstInput={state.type !== "collector" ? "collected by" : "collected at"}
              labelSecondInput="year"
              setErrorFields={setErrorFields}
              errorFields={errorFields}
              showErrors={showErrors}
              isUploadArtwork={isUploadArtwork}
            />
          </div>
          {state.type === "collector" && <Provenance />}
          {state.type === "collector" && <LocationCreated />}
          <div className="register_item">
            <InputLabel className="origin_label" variant="standard" htmlFor="condition_report">
              Condition Report
            </InputLabel>
            <TextField
              id="condition_report"
              className="input"
              type="text"
              name="name"
              variant="standard"
              value={conditionReport}
              onChange={handleChangeConditionReport}
            />
            {isUploadArtwork &&
              !typeFound &&
              (!errorFields.conditionReportError || !showErrors) && (
                <p className="info_message">Condition report is required</p>
              )}
            {showErrors && errorFields.conditionReportError && (
              <p className="error_message">{errorFields.conditionReportError}</p>
            )}
          </div>
          <div className="register_item">
            <InputLabel className="origin_label" variant="standard" htmlFor="notes">
              Notes, Tools & Techniques
            </InputLabel>
            <TextField
              id="notes"
              className="input"
              type="text"
              name="name"
              variant="standard"
              value={notes}
              onChange={handleChangeNotes}
            />
            {isUploadArtwork && typeArtist && (!errorFields.notesError || !showErrors) && (
              <p className="info_message">Notes, tools & techniques is required</p>
            )}
            {showErrors && errorFields.notesError && (
              <p className="error_message">{errorFields.notesError}</p>
            )}
          </div>
          <div className="register_item">
            <InputLabel className="origin_label" variant="standard" htmlFor="video_url">
              Video URL
            </InputLabel>
            <div className="register_item__url">
              <div className="copy">
                <TextField
                  id="video_url"
                  className="input"
                  type="text"
                  name="name"
                  variant="standard"
                  value={videoUrl}
                  onChange={handleChangeVideoUrl}
                />
                <p className="under_input">Provide video URL from YouTube or Vimeo</p>
                {errorFields.videoUrlError && (
                  <p className="error_message">{errorFields.videoUrlError}</p>
                )}
              </div>
              <MyButton type="button" onClick={pasteLink}>
                Paste
              </MyButton>
            </div>
          </div>

          <div className="register_item">
            <EditDoubleInput
              values={pastExhibitions}
              setValues={setPastExhibitions}
              labelFirstInput="exhibition/appraisal"
              labelSecondInput="year"
              type="exhibitions"
              errorFields={errorFields}
              setErrorFields={setErrorFields}
              showErrors={showErrors}
              isUploadArtwork={isUploadArtwork}
            />
          </div>
        </div>
        {isUploadArtwork ? (
          <div className="upload_buttons_wrapper">
            <MyButton
              type="button"
              className="submit_form"
              variant="button-dark"
              children="FINISH LATER"
              onClick={() => handleUpdateArtwork(false)}
            />
            <MyButton
              type="submit"
              className="submit_form"
              variant="button-dark"
              children="UPLOAD"
            />
          </div>
        ) : (
          <MyButton className="submit_form" variant="button-dark" children="Save Changes" />
        )}
      </div>
    </form>
  );
};

export default EditArtworkArtist;
