import React from "react";
import { useQuery } from "@apollo/client";

import TextTemplateNotification from "./TextTemplateNotification";
import { artists, explore } from "../../route-link";
import NotificationTime from "./NotificationTime";
import { UserRequests } from "../../api-queries/AllRequests";
import NotificationImage from "./NotificationImage";

interface PublicCommentsPostProps {
  nameFollower: string;
  userId: string;
  imageSrc: string;
  textComment: string;
  time: Date;
}

const PublicCommentsPostNotification: React.FC<PublicCommentsPostProps> = ({
  nameFollower,
  userId,
  time,
  imageSrc,
  textComment,
}) => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const { data: userData } = useQuery(UserRequests.USER_DETAILS, {
    variables: { _id: authUserId },
  });
  const nameUser = `${userData?.user.profile.firstName} ${userData?.user.profile.secondName}`;
  return (
    <div className="notification_grid">
      <TextTemplateNotification
        messageText={`commented: "${textComment}" on show shared by `}
        nameFollower={nameFollower}
        linkNameFollower={`${explore}/${artists}/${userId}`}
        nameUser={nameUser}
        linkNameUser={`${explore}/${artists}/${authUserId}`}
      />
      <NotificationTime time={time} />
      {/* Todo link to post */}
      <div className="notification_border">
        <h4>{nameUser}</h4>
        <p className="notification_time">We appreciate any art</p>
        {imageSrc && <NotificationImage imageSrc={imageSrc} alt="Artwork" />}
      </div>
    </div>
  );
};

export default PublicCommentsPostNotification;
