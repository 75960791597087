import React from "react";
import { ReturnActionType } from "../../../../store/types/return-type";
import { useActions } from "../../../../hooks/useActions";
import { VoteListArtworksProps } from "../../vote-explore-page.type";

const VoteListArtworks: React.FC<VoteListArtworksProps> = ({
  artwork,
  widthColumn,
  paramsArtwork,
}): JSX.Element => {
  const { openModal } = useActions();
  const openModalWindow = (): ReturnActionType => openModal();
  return (
    <div
      onClick={openModalWindow}
      className="artwork_item"
      style={{ minHeight: Math.floor(widthColumn / paramsArtwork) }}
    >
      <img className="artwork_item__image" src={artwork.image.medium.url} alt={artwork.name} />
    </div>
  );
};
export default VoteListArtworks;
