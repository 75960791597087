import React from "react";
import { Link } from "react-router-dom";
import { artists, artworks, explore } from "../../route-link";
import NotificationTime from "./NotificationTime";
import NotificationImage from "./NotificationImage";

interface AcceptArtworkSubmissionNotificationProps {
  nameFrom: string;
  userId: string;
  artworkId: string;
  imageSrc: string;
  nameAcceptArtwork: string;
  time: Date;
}

const AcceptArtworkSubmissionNotification: React.FC<AcceptArtworkSubmissionNotificationProps> = ({
  userId,
  nameFrom,
  artworkId,
  time,
  imageSrc,
  nameAcceptArtwork,
}): JSX.Element => {
  return (
    <div className="notification_grid">
      <div className="notification_text">
        <Link className="notification_link" to={`${explore}/${artists}/${userId}`}>
          {nameFrom}
        </Link>{" "}
        accepted an artwork to virtual show{" "}
        <Link className="notification_link" to={`${explore}/${artworks}/${artworkId}`}>
          {nameAcceptArtwork || "some name"}
        </Link>
      </div>
      <NotificationTime time={time} />
      <NotificationImage imageSrc={imageSrc} alt="Avatar" />
    </div>
  );
};

export default AcceptArtworkSubmissionNotification;
