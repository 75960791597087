import React from "react";
import { useQuery } from "@apollo/client";

import { Link } from "react-router-dom";
import { artworks, explore } from "../../route-link";
import { UserRequests } from "../../api-queries/AllRequests";
import TextTemplateNotification from "./TextTemplateNotification";
import NotificationTime from "./NotificationTime";
import NotificationImage from "./NotificationImage";

interface LikedArtworkNotificationProps {
  nameFollower: string;
  artworkId: string;
  userId: string;
  imageSrc: string;
  nameAvatar: string;
  time: Date;
}

const LikeArtworkNotification: React.FC<LikedArtworkNotificationProps> = ({
  nameFollower,
  time,
  userId,
  imageSrc,
  nameAvatar,
  artworkId,
}) => {
  const authUserId = localStorage.getItem("myMOCAId") || "";
  const { data: userData } = useQuery(UserRequests.USER_DETAILS, {
    variables: { _id: authUserId },
  });
  const nameUser = `${userData?.user.profile.firstName} ${userData?.user.profile.secondName}`;
  return (
    <div className="notification_like__artwork">
      <TextTemplateNotification
        messageText={`liked artwork "${nameAvatar}" uploaded by `}
        nameFollower={nameFollower}
        linkNameFollower={`${explore}/${artworks}/${userId}`}
        nameUser={nameUser}
        linkNameUser={`${explore}/${artworks}/${authUserId}`}
      />
      <NotificationTime time={time} />
      <Link to={`${explore}/${artworks}/${artworkId}`}>
        <NotificationImage imageSrc={imageSrc} alt="Artwork" />
      </Link>
    </div>
  );
};

export default LikeArtworkNotification;
