import React, { FC, useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { toggleFollowing } from "../../api-queries/GraohQlClient";
import { useActions } from "../../hooks/useActions";
import { getMyFollowings, useGetMe } from "../../hooks/useGetMe";
import { IUser } from "../../types/userTypes";
import Button from "../../UI/button/Button";
import { getColorByUserId } from "../artist-or-institution-profile/utils";

import "./ArtistItem.scss";
import { artists, explore } from "../../route-link";

interface ArtistItemProps {
  artist: IUser;
  saveState?: () => void;
}
const ArtistItem: FC<ArtistItemProps> = ({ artist, saveState }) => {
  const { me, updateAuthUser } = useGetMe();

  const token = localStorage.getItem("tokenKey");
  const [isFollow, setIsFollow] = useState<boolean>();
  const backgroundImage = artist?.profile?.bannerImage?.medium?.url;
  const avatarImage = artist?.profile?.image?.medium?.url;
  const id = artist?._id;
  const firstName = artist?.profile?.firstName;
  const middleName = artist?.profile?.middleName || "";
  const secondName = artist?.profile?.secondName;
  const backgroundColor = getColorByUserId(id)[0];
  const preventLink = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    if ((e.target as Element).className.includes("follow_button")) {
      e.preventDefault();
    }
  };
  const goToTop = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (saveState) saveState();
    if ((e.target as Element).className.includes("follow_button")) return;
    window.scroll(0, 0);
  };
  const { openModalLogin } = useActions();

  const toggleFollowUser = async (): Promise<void> => {
    setIsFollow(!isFollow);
    await toggleFollowing({ userId: id });
    updateAuthUser();
  };

  const onFollowClick = token ? toggleFollowUser : openModalLogin;

  useEffect(() => {
    const myFollows = me && getMyFollowings(me);
    setIsFollow(Boolean(myFollows?.find((item: { id: string }) => item.id === id)));
  }, [me]);

  const name = `${firstName} ${middleName} ${secondName}`;

  return (
    <div onClick={goToTop} style={{ backgroundColor }} className="artist_page_item">
      <Link
        onClick={preventLink}
        className="artist_page_item__link"
        to={`/${explore}/${artists}/${id}`}
        state={{ image: avatarImage, name }}
      >
        {backgroundImage ? (
          <img
            className="artist_page_item__background"
            src={backgroundImage}
            alt="backgroundImage"
          />
        ) : (
          <p className="artist_page_item__moca_text">MOCA</p>
        )}

        <div className="artist_page_item__info">
          <div className="artist_page_item__avatar">
            {avatarImage && (
              <Avatar
                className="artist_page_item__avatar_icon"
                src={avatarImage}
                sx={{ width: 50, height: 50 }}
              />
            )}
            <h3 className="artist_page_item__name">{name}</h3>
          </div>
          <Button onClick={onFollowClick} color="none" className="artist_page_item__follow_button">
            {isFollow ? "Following" : "Follow"}
          </Button>
        </div>
      </Link>
    </div>
  );
};

export default ArtistItem;
